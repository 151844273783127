import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Helmet } from 'react-helmet';

const StyledApprovalPage = styled.div`
    ${props => props.brand && `
        h2 {
            color: ${props.brand.colors.theme} !important;
        }

        .primaryButton {
            background-color: ${props.brand.colors.theme} !important;
            border: 2px solid ${props.brand.colors.theme} !important;

            &:hover {
                color: ${props.brand.colors.white} !important;
            }
        }
    `}
`;
 
const ApprovalPage = () => {
    const account = useSelector((state) => state.account);
    const { brand } = account;

    return <Fragment>
        <Helmet>
            <title>Sign Up | Premier Pharmaceuticals</title>
            <link rel="icon" href={brand?.icons.favIcon} />
        </Helmet>
        <StyledApprovalPage brand={brand} className="approval-page d-flex align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={brand?.icons.signup.confirm} alt="" />
                <h2>Thank you for registering!</h2>
                <p>
                    We are excited to have you as our partner! Your account verification is in progress. Please expect a response within 24-48 hours.
                </p>
                <a
                    href={brand && process.env[brand.homePageUrlKey]}
                    className="primaryButton"
                >
                    Return to Homepage
                </a>
            </div>
        </StyledApprovalPage>
    </Fragment>
};

export default ApprovalPage;
