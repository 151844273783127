import React, { useState, useEffect } from "react";
import { HeaderNav } from "../../components/partials/HeaderNav";
import { Footer } from "../../components/partials/Footer";
import { PersonalInfo } from "../../components/account/personalInfo";
import { License } from "../../components/account/license";
import { Addresses } from "../../components/account/addresses";
import { OrdersHistory } from "../../components/account/orders";
import { Changepassword } from "../../components/account/changePassword";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../actions/auth";
import { getAccount } from "../../actions/account";

const StyledWrapper = styled.div`
    ${props => props.brand && `
        background: ${props.brand.colors.background} !important;
        
        .side-nav {
            .primary-link.active {
                background: ${props.brand.colors.accountSideNav} !important;
            }
        }    
    `}
`;

const StyledInformation = styled.div`
    ${props => props.brand && `
        .nav-tab.active {
            color: ${props.brand.colors.theme} !important;
            border-bottom: 2px solid ${props.brand.colors.theme} !important;
        }
    `}
`;

export const PersonalInformationContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const user = JSON.parse(localStorage.getItem("profile"));
  const account = useSelector((state) => state.account);
  const { brand } = account;

  const [activeTab, setActiveTab] = useState("");

  const handleLogout = () => {
    dispatch(logOut(user?.username, history));
  };

  useEffect(() => {
    if(brand) {
        const user = JSON.parse(localStorage.getItem("profile"));
        dispatch(getAccount(user?.username, brand?.region));
        
        if (window.location.href.indexOf("order-history") > -1) {
            setActiveTab(true);
        } else {
            setActiveTab(false);
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  return (
    <>
        <Helmet>
            <title>Account | Premier Pharmaceuticals</title>
            <link rel="icon" href={brand?.icons.favIcon} />
        </Helmet>
        <HeaderNav />
        <StyledWrapper brand={brand} className="personalInformationWrapper">
            <div className="container">
                <div className="d-flex align-items-start flex-column flex-lg-row">
                    <div className="side-nav">
                        <h1 className="title">Account</h1>
                        <div className="card">
                            <ul
                                className="nav nav-tabs flex-column"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="primary-nav">
                                    <a
                                        data-toggle="tab"
                                        href="#personal-profile"
                                        className={`primary-link ${activeTab ? "" : "active"} `}
                                    >
                                    <img src={brand?.icons.account.user} alt="" />
                                        Personal Information{" "}
                                    </a>
                                </li>
                                <li className="primary-nav">
                                    <a
                                        data-toggle="tab"
                                        href="#order-history"
                                        className={`primary-link ${activeTab ? "active" : ""} `}
                                    >
                                    <img src={brand?.icons.account.cart} alt="" />
                                        Order History
                                    </a>
                                </li>
                                <li className="primary-nav">
                                    <a
                                        data-toggle="tab"
                                        href="#security"
                                        className="primary-link "
                                    >
                                    <img src={brand?.icons.account.security} alt="" />
                                        Security
                                    </a>
                                </li>
                                <li className="primary-nav">
                                    <a
                                        data-toggle="tab"
                                        href="#!"
                                        onClick={handleLogout}
                                        className="primary-link "
                                    >
                                    <img src={brand?.icons.account.logout} alt="" />
                                        Log Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <StyledInformation brand={brand} className="information">
                        <div id="pills-tabContent" className="tab-content" >
                            <div
                                className={`tab-pane fade ${activeTab ? "" : "active show"}`}
                                id="personal-profile"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                <ul class="nav nav-tabs">
                                    <li>
                                        <a
                                            className="nav-tab active"
                                            data-toggle="pill"
                                            href="#my-profile"
                                            role="tab"
                                            aria-controls="pills-home-1"
                                            aria-selected="true"
                                        >
                                            My Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="nav-tab"
                                            data-toggle="pill"
                                            href="#my-license"
                                            role="tab"
                                            aria-controls="pills-home-2"
                                            aria-selected="true"
                                        >
                                            My License
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="nav-tab"
                                            data-toggle="pill"
                                            href="#my-addresses"
                                            role="tab"
                                            aria-controls="pills-home-3"
                                            aria-selected="false"
                                        >
                                            My Address Book
                                        </a>
                                    </li>
                                </ul>
                                <div className="card tab-content" id="pills-tab-1">
                                    <div
                                        className="tab-pane fade show active"
                                        id="my-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-1"
                                    >
                                        <PersonalInfo brand={brand} account={account} />
                                    </div>
                                    <div
                                        className="tab-pane fade show"
                                        id="my-license"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-2"
                                    >
                                        <License brand={brand} account={account} />
                                    </div>
                                    <div
                                        className="tab-pane fade show"
                                        id="my-addresses"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-3"
                                    >
                                        <Addresses
                                            key={account.addressesData}
                                            account={account}
                                            brand={brand} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                id="order-history"
                                className={`tab-pane fade ${activeTab ? "active show" : ""}`}
                            >
                                <OrdersHistory />
                            </div>
                            <div id="security" className="tab-pane fade">
                                <h1 className="title">Security</h1>
                                <div className="card">
                                    <Changepassword account={account} />
                                </div>
                            </div>
                        </div>
                    </StyledInformation>
                </div>
            </div>
        </StyledWrapper>
        <Footer />
    </>
  );
};
