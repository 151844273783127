import React, { useState, useEffect } from "react";
import { addCoupon, getCoupons, deleteCoupon } from "../../actions/admin";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

const StyledAddButton = styled.button`
  ${(props) =>
    props.brand &&
    `
    background: ${props.brand.colors.theme} !important;
    border: 2px solid ${props.brand.colors.theme} !important;
  `}
`;

const StyledPricingGroupTable = styled.div`
  ${(props) =>
    props.brand &&
    `
    .table .thead-dark th {
      // background-color: ${props.brand.colors.black} !important;
      // border-color: ${props.brand.colors.black} !important;
    }
  `}
`;

const initialState = {
  code: "",
  percentage_off: 0,
  name: "",
};

export default () => {
  const admin = useSelector((state) => state.admin);
  const account = useSelector((state) => state.account);
  const { brand } = account;
  const [actionLoading, setActionLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [couponSelected, setCouponSelected] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [alertType, setAlertType] = useState("error");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();

  const handleAddCoupon = async () => {
    const couponToAdd = {
      ...formData,
    };
    const response = await dispatch(addCoupon(couponToAdd));

    setActionLoading(false);
    response?.status === 404 && handleAlert(response?.message, "error");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAlert = (msg, type) => {
    setErrorMsg(msg);
    setShowError(true);
    setAlertType(type);
    setTimeout(function () {
      setShowError(false);
    }, 3000);
  };

  useEffect(() => {
    dispatch(getCoupons());
  }, [dispatch]);

  return (
    <>
      <StyledPricingGroupTable brand={brand} className="pricing-group-table">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="no-wrap" scope="col">
                Code
              </th>
              <th className="no-wrap" scope="col">
                Percentage Off
              </th>
              <th scope="col">Name</th>
              <th className="no-wrap" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {admin.discounts.map((discount, discountIndex) => (
              <tr key={`discount${discountIndex}`}>
                <td className="no-wrap">{discount.code}</td>
                <td className="no-wrap">{discount.percentage_off}</td>
                <td className="no-wrap">{discount.name}</td>
                <td className="no-wrap">
                <StyledAddButton
                    className="btn btn-danger ml-2"
                    onClick={async () => {
                      setCouponSelected(discount.couponId)
                      setDeleteLoader(true);
                      await dispatch(deleteCoupon(discount.couponId));
                      setDeleteLoader(false);
                      setCouponSelected("")
                    }}
                  >
                    {(couponSelected === discount.couponId && deleteLoader) ? (
                      <div
                        className="spinner-border text-light spinner-border-sm"
                        role="deleting"
                      >
                        <span className="btn btn-primary w-25 sr-only">
                          Deleting...
                        </span>
                      </div>
                    ) : (
                      "Delete"
                    )}
                    </StyledAddButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </StyledPricingGroupTable>
      <div className="d-flex align-items-end justify-content-between add-pricing-group">
        <div className="position-relative group-search-product w-50">
          <label>Coupon Code</label>
          <input
            name="code"
            className="form-control"
            type="text"
            placeholder="code"
            value={formData?.code}
            onChange={handleChange}
          />
        </div>
        <div className="w-25">
          <label>Percentage Off</label>
          <input
            className="form-control"
            type="number"
            placeholder="percentage_off"
            name="percentage_off"
            value={formData?.percentage_off}
            onChange={handleChange}
          />
        </div>
        <div className="w-25">
          <label>Name</label>
          <input
            className="form-control"
            type="text"
            placeholder="name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
          />
        </div>
        <StyledAddButton
          brand={brand}
          className="btn btn-primary w-25"
          onClick={() => {
            setActionLoading(true);
            handleAddCoupon();
          }}
          disabled={!formData?.code || !(formData?.percentage_off > 0)}
        >
          {actionLoading ? (
            <div
              className="spinner-border text-light spinner-border-sm"
              role="adding"
            >
              <span className="btn btn-primary w-25 sr-only">Adding...</span>
            </div>
          ) : (
            "Add Coupon"
          )}
        </StyledAddButton>
      </div>
      <div
        id="toast"
        className={
          "toast alert " +
          (alertType === "error" ? "alert-danger " : "alert-success ") +
          (showError ? "show" : "")
        }
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="2000"
        style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
      >
        {errorMsg}
      </div>
    </>
  );
};
