import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderNav } from "../components/partials/HeaderNav";
import { Footer } from "../components/partials/Footer";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { Cards } from "../components/account/cards";
import { Helmet } from "react-helmet";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router";
import { paymentByTerms, paymentViaPaypal } from "../actions/payment";
// import { getCart } from "../actions/cart";
// eslint-disable-next-line no-unused-vars
import { getAccount, getNetsuiteAccount } from "../actions/account";

// import Mastercard from "../assets/img/mastercard.svg";
// import Visa from "../assets/img/visa.svg";
import Paypal from "../assets/img/paypal.svg";
import styled from "styled-components";
import "../components/commons/modal.scss";

const StyledCartHeader = styled.div`
    ${props => props.brand && `
        background: ${props.brand.colors.cartBackground} !important;
    `}
`;
const StyledItemCount = styled.span`
    ${props => props.brand && `
        background: ${props.brand.colors.supporting} !important;
    `}
`;

const StyledMainAdmin = styled.div`
  ${props => props.brand && `
    background: ${props.brand.colors.background} !important;

    .modal-buttons .btn-success,
    .modal-buttons .sync-btn,
    .modal-buttons .login-user-btn {
      color: ${props.brand.colors.white} !important;
    }

    .modal-buttons .login-user-btn {
      background: ${props.brand.colors.theme} !important;
      border-color: ${props.brand.colors.theme} !important;
    }

  `}
`;

const StyledCartPage = styled.div`
    ${props => props.brand && `
        .cart-progress {
            background: ${props.brand.colors.cartBackground} !important;
        }

        .nav-tabs li a.active {
            border: 1px solid ${props.brand.colors.theme} !important;
        }

        .title {
            color: ${props.brand.colors.theme} !important;
        }

        .proceed-btn {
            background: ${props.brand.colors.theme} !important;

            &:hover {
                background: ${props.brand.colors.cartBackground} !important;
            }
        }

        .back-btn {
            border: 2px solid ${props.brand.colors.theme} !important;
            color: ${props.brand.colors.theme} !important;

            &:hover {
                border: 2px solid ${props.brand.colors.cartBackground} !important;
                color: ${props.brand.colors.cartBackground} !important;
            }
        }
    `}
`;

export const PaymentContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cart = useSelector((state) => state.cart);
    const account = useSelector((state) => state.account);
    const { brand } = account;

    const payment = useSelector((state) => state.payment);
    const [selectedCard, setSelectedCard] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState("");
    const [showError, setShowError] = useState(false);
    const [alertType, setAlertType] = useState("error");
    const [errorMsg, setErrorMsg] = useState("");
    const [showPayPalButton, setPayPalButton] = useState(false);
    // const [enablePayByTerms, setEnablePayByTerms] = useState(false);

    const handlePayment = () => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem("profile"));
        const accountData = account?.accountData;
        const amount = parseFloat(cart?.checkoutDetail?.total);
        const billingAddress = cart?.checkoutDetail?.selectedBilling;
        const shippingAddress = cart?.checkoutDetail?.selectedShipping;
        const customerRefNumber = cart?.checkoutDetail?.customerRefNumber;
        const role = user.roles && user.AuthenticationResult ? "admin" : "user";
        const body = {
            code: cart?.discountDetail?.discount_data?.name,
            // paymentMethodId: selectedCard,
            cardNonce: selectedCard,
            amount,
            billingAddress,
            shippingAddress,
            accountData,
            customerRefNumber,
            role,
        };
        selectedMethod === "paypal"
            ? dispatch(paymentViaPaypal(user?.username, body, history, brand.region))
            : dispatch(paymentByTerms(user?.username, body, history, brand.region));
    };

    const closeModal = () => {
        setPayPalButton(false);
    }

    const onApprove = async (data, actions) => {
        try {
            await actions.order.capture();
            setPayPalButton(false);
            handleAlert("Payment successful!", "success")
            setSelectedMethod("paypal");
            handlePayment();
        } catch (error) {
            handleAlert(error, "error")
        }
    };
    useEffect(() => {
        if (brand) {
            const user = JSON.parse(localStorage.getItem("profile"));
            dispatch(getAccount(user?.username, brand?.region));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand]);

    const handleAlert = (msg, type) => {
        setErrorMsg(msg);
        setShowError(true);
        setAlertType(type);
        setTimeout(function () {
            setShowError(false);
        }, 7000);
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        intent: "capture",
    };

    useEffect(() => {
        if (!cart?.checkoutDetail) history.push("/checkout");
    }, [cart]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (payment.paymentError) {
            handleAlert(payment.errorMessage, "error")
        }
        setIsLoading(false);
    }, [payment]);

    return (
      <>
        <Helmet>
          <title>Payment | Premier Pharmaceuticals</title>
          <link rel="icon" href={brand?.icons.favIcon} />
        </Helmet>
        <HeaderNav />
        <StyledCartHeader
          brand={brand}
          className="cart-header container-fluid d-flex align-items-center justify-content-between"
        >
          <span>Make Payment</span>
          <Link to="/checkout" className="back-btn">
            {"<"}
            &nbsp;&nbsp;Back to shipping
          </Link>
        </StyledCartHeader>
        <StyledCartPage brand={brand} className="container-fluid payment-page">
          <div className="main-content-container d-flex align-items-start">
            <div className="payments-container">
              <div className="cart-progress d-flex align-items-center justify-content-between">
                <div className="step d-flex align-items-center justify-content-center">
                  <img
                    height={20}
                    width={20}
                    src={brand?.icons.cart.done}
                    alt=""
                  />
                  <span>Cart</span>
                </div>
                <div>
                  <img height={14} src={brand?.icons.cart.arrow} alt="" />
                </div>
                <div className="step d-flex align-items-center justify-content-center">
                  <img
                    height={20}
                    width={20}
                    src={brand?.icons.cart.done}
                    alt=""
                  />
                  <span>Shipping Information</span>
                </div>
                <div>
                  <img height={14} src={brand?.icons.cart.arrow} alt="" />
                </div>
                <div className="step d-flex align-items-center justify-content-center">
                  <img
                    height={20}
                    width={20}
                    src={brand?.icons.cart.step3Next}
                    alt=""
                  />
                  <span>Make Payment</span>
                </div>
                <div>
                  <img height={14} src={brand?.icons.cart.arrow} alt="" />
                </div>
                <div className="step d-flex align-items-center justify-content-center">
                  <img
                    height={20}
                    width={20}
                    src={brand?.icons.cart.step4Next}
                    alt=""
                  />
                  <span>Complete</span>
                </div>
              </div>
              <div className="payments mb-4">
                <h2>Payment Method</h2>
                <label>Choose what service you want for your transaction</label>

                <ul className="nav nav-tabs">
                  <li>
                    <a
                      data-toggle="tab"
                      href="#paypal"
                      className=""
                      onClick={() => {
                        setPayPalButton(true);
                        setSelectedMethod("");
                      }}
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <span className="payment-title">Paypal</span>
                          <div className="logo-container d-flex align-items-center justify-content-end">
                            <img src={Paypal} alt="Paypal Icon" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      data-toggle="tab"
                      href="#terms"
                      className=""
                      onClick={() => setSelectedMethod("terms")}
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex w-100">
                          <span className="payment-title">Pay by Terms</span>
                          <div className="logo-container d-flex align-items-center justify-content-end">
                            {/* <img src={Mastercard} alt='Master Icon'/>
                                            <img src={Visa} alt='Master Icon'/> */}
                          </div>
                        </div>
                        <span className="payment-description"></span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="payments">
                <div className="tab-content">
                  {/* id="terms" className="tab-pane fade active show" */}
                  <div className="terms-copy d-flex align-items-center justify-content-between">
                    <div className="mr-5">
                      <h6>REMINDER</h6>
                      <ul>
                        <li>
                          If payment terms have already been established you
                          will receive an invoice via email once the order has
                          been processed.
                        </li>
                        <li>
                          If payment terms have not been established, you agree
                          that within 24 hours Premier Pharmaceuticals will
                          confirm your eligibility to use this payment option.
                          Your dedicated sales rep will contact you with
                          confirmation.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="total-container d-flex flex-column">
                <div className="total-amount-container">
                  <h1 className="title">Total Amount</h1>
                  <div className="d-flex align-items-end justify-content-center">
                    <p className="amount">
                      ${cart?.checkoutDetail?.finalTotal} <span> USD</span>
                    </p>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-end actions-container">
                  <Link to="checkout" className="btn back-btn">
                    {"<"}
                    <span> Shipping</span>
                  </Link>
                  {/* disabled={enablePayByTerms ? false : true} */}
                  <button
                    className="btn proceed-btn"
                    onClick={() => handlePayment()}
                    disabled={isLoading || selectedMethod !== "terms"}
                  >
                    {isLoading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Finalize Order"
                    )}
                  </button>
                </div>
              </div>
              <div className="total-container d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <h2>Order Summary</h2>
                  <StyledItemCount brand={brand} className="item-count">
                    {cart?.checkoutDetail?.itemCount} items
                  </StyledItemCount>
                </div>
                <ul className="summary-list">
                  <li>
                    <div>
                      <p>Subtotal</p>
                    </div>
                    <p>${cart?.checkoutDetail?.subTotal}</p>
                  </li>
                  <li>
                    <div>
                      <p>Shipping Fee</p>
                      {cart?.checkoutDetail?.shipping < 150 && (
                        <span>
                          Add ${cart?.checkoutDetail?.shippingCounter} for free
                          shipping
                        </span>
                      )}
                    </div>
                    <p>${cart?.checkoutDetail?.shipping}</p>
                  </li>
                  <li>
                    <div>
                      <p>Discount</p>
                    </div>
                    <p className="discount-code-color">
                      -${cart?.checkoutDetail?.discountAmount}
                    </p>
                  </li>
                </ul>
                <div className="d-flex align-items-center justify-content-between total">
                  <label>Total</label>
                  <span>${cart?.checkoutDetail?.finalTotal}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            id="toast"
            className={
              "toast alert " +
              (alertType === "error" ? "alert-danger " : "alert-success ") +
              (showError ? "show" : "")
            }
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-delay="2000"
            style={{ position: "fixed", bottom: "1rem", right: "1rem" }}
          >
            {errorMsg}
          </div>
          {showPayPalButton && (
            <StyledMainAdmin brand={brand}>
              <div className="modal-container" onClick={closeModal}>
                <div className="modal-content">
                  <div className="paypal">
                    <PayPalScriptProvider options={initialOptions}>
                      <PayPalButtons
                        style={{
                          color: "blue",
                          label: "paypal",
                          layout: "vertical",
                          shape: "pill",
                          tagline: false,
                          disableMaxWidth: true,
                          funding: false,
                        }}
                        disableFunding={{
                          disallowed: ["paylater", "venmo"],
                        }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: parseFloat(
                                    cart?.checkoutDetail?.total
                                  ),
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={onApprove}
                      />
                    </PayPalScriptProvider>
                  </div>
                </div>
              </div>
            </StyledMainAdmin>
          )}
        </StyledCartPage>
        <Footer />
      </>
    );
};
