import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useStep } from "react-hooks-helper";
import styled from "styled-components";
import Basic from "./multistep/basic";
import Licensing from "./multistep/licensing";
import Account from "./multistep/account";
import Done from "../../assets/img/Account/done.svg"
// import Basic from "./basic";
// import Address from "./address";
// import Document from "./document";
// import Account from "./account";
// import Verification from "./verification";

const SignUpBox = styled.div`
    ${props => props.brand && `
        .signup-progress {
            background: ${props.brand.colors.supporting} !important; 
        }

        .next-btn {
            background: ${props.brand.colors.theme} !important; 
        }

        .checkbox-container .checkbox a {
            color: ${props.brand.colors.black} !important; 
        }
        
        .checkbox-container input[type="checkbox"]:checked {
            color: ${props.brand.colors.theme} !important; 
        }
    `}
`;

const steps = [
    { id: "basic" },
    { id: "licensing" },
    { id: "account" },
    { id: "verification" }
];

export const MultiStepForm = (componentProps) => {
    const [formData, setFormData] = useState({
        givenName: "",
        familyName: "",
        email: "",
        password: "",
        confirm_password: "",
        phoneNumber: "",
        address: "",
        company: "",
        city: "",
        state: "",
        stateLicenseNumber: "",
        stateLicenseExpirationDate: "",
        dea: "",
        deaExpiry: "",
        apEmail: "",
        apContact: "",
        apPhone: "",
        methodOfCollection: "",
        postalCode: "",
        country: "",
        countryCode: "",
        code: "",
    });

    const auth = useSelector((state) => state.auth);
    const { step, navigation } = useStep({ initialStep: 0, steps });
    const { id } = step;

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const props = { auth, formData, setFormData, navigation, onChange };
    const { brand } = componentProps;

    switch (id) {
        case "basic":
            return <SignUpBox brand={brand} className="d-flex flex-column align-items-center justify-content-center w-100">
                <div className="signup-progress d-flex align-items-center justify-content-between">
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={brand?.icons.signup.step1} alt="Step 1" />
                        <span>Step 1</span>
                    </div>
                    <div>
                        <img height={14} src={brand?.icons.signup.arrow} alt="Next" />
                    </div>
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={brand?.icons.signup.step2Next} alt="Step 2" />
                        <span>Step 2</span>
                    </div>
                    <div>
                        <img height={14} src={brand?.icons.signup.arrow} alt="Next" />
                    </div>
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={brand?.icons.signup.step3Next} alt="Step 3" />
                        <span>Step 3</span>
                    </div>
                </div>
                <Basic {...props} />
            </SignUpBox>
        case "licensing":
            return <SignUpBox brand={brand} className="d-flex flex-column align-items-center justify-content-center w-100">
                <div className="signup-progress d-flex align-items-center justify-content-between">
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={Done} alt="Done" />
                        <span>Step 1</span>
                    </div>
                    <div>
                        <img height={14} src={brand?.icons.signup.arrow} alt="Next" />
                    </div>
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={brand?.icons.signup.step2} alt="Step 2" />
                        <span>Step 2</span>
                    </div>
                    <div>
                        <img height={14} src={brand?.icons.signup.arrow} alt="Next" />
                    </div>
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={brand?.icons.signup.step3Next} alt="Step 3" />
                        <span>Step 3</span>
                    </div>
                </div>
                <Licensing {...props} />
            </SignUpBox>
        case "account":
            return <SignUpBox brand={brand} className="d-flex flex-column align-items-center justify-content-center w-100">
                <div className="signup-progress d-flex align-items-center justify-content-between">
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={Done} alt="Done" />
                        <span>Step 1</span>
                    </div>
                    <div>
                        <img height={14} src={brand?.icons.signup.arrow} alt="Next" />
                    </div>
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={Done} alt="Done" />
                        <span>Step 2</span>
                    </div>
                    <div>
                        <img height={14} src={brand?.icons.signup.arrow} alt="Next" />
                    </div>
                    <div className="step d-flex align-items-center justify-content-center">
                        <img height={20} width={20} src={brand?.icons.signup.step3} alt="Step 3" />
                        <span>Step 3</span>
                    </div>
                </div>
                <Account brand={brand} {...props} />
            </SignUpBox>
        default:
            return null;
    }
};

export default MultiStepForm;
