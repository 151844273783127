import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { getSubscribers } from "../../actions/admin";

import Table from "../../components/commons/table";
import OrderDetails from "./modal/orderDetails";
import styled from "styled-components";
import jsPDF from 'jspdf';
import premierPharmaLogo from '../../../src/assets/img/logo.png';
import midAmericaLogo from '../../../src/assets/img/Mid-America-logo.png';

const Styles = styled.div`
    padding: 1rem;

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            },
        }

        th, td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
    }

    ${props => props.brand && `
      .table .thead-dark th {
        background-color: ${props.brand.colors.black} !important;
        border-color: ${props.brand.colors.black} !important;
      }
    `}
`;

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="admin-table-filter-select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export default (props) => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const account = useSelector((state) => state.account);
  const { brand } = account;

  const [logs, setLogs] = useState([]);
  // const [orderDetails, setOrderDetails] = useState([]);
  /* React Table Columns */
  const columns = useMemo(
    () => [
      // {
      //   Header: "Serial Number",
      //   accessor: "serialnumber",
      // },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  /* Upon page load, get logs */
  useEffect(() => {
    if (brand) {
      dispatch(getSubscribers(brand.region));
    }
  }, [dispatch, brand]);

  useEffect(() => {
    setLogs(admin.subscribers);
  }, [admin]);

  const generatePDF = (emails) => {
    const pdf = new jsPDF();
    pdf.text(10, 10, 'List of Emails');

    let x = 10;
    let y = 20;
    const rowHeight = 10;
    const colWidth = 50;
    const maxPageHeight = 300;

    pdf.rect(x, y, colWidth, rowHeight);
    pdf.text(x + 5, y + 7, 'Serial No');
    pdf.rect(x + colWidth, y, colWidth + 50, rowHeight);
    pdf.text(x + colWidth + 5, y + 7, 'Email Address');


    let serialNumber = 1;

    y += rowHeight;
    emails.forEach((obj) => {
      if (y + 6 * rowHeight > maxPageHeight) {
        pdf.addPage();
        y = 20;
      }
      pdf.rect(x, y, colWidth, rowHeight);
      pdf.text(x + 5, y + 7, serialNumber.toString());
      pdf.rect(x + colWidth, y, colWidth + 50, rowHeight);
      pdf.text(x + colWidth + 2, y + 7, obj.email);

      serialNumber++;
      y += rowHeight;
    });

    const image =
      brand && brand.name === "premierpharma"
        ? premierPharmaLogo
        : midAmericaLogo;
    const imageWidth = 40;
    const imageHeight = 10;
    pdf.addImage(image, "PNG", x + 150, 4, imageWidth, imageHeight);

    pdf.save('subscribers-list.pdf');
  };

  return (
    <>
      <Helmet>
        <title> Admin Dashboard | Premier Pharmaceuticals</title>
        <link rel="icon" href={brand?.icons.favIcon} />
      </Helmet>

      <div className="d-flex align-items-center justify-content-center admin-pages">
        <div className="card container">
          <div className="d-flex align-items-center justify-content-between mb-4 header">
            <h2 className="m-0">Order Logs</h2>
            <div>
              <button
                className="btn btn-primary ml-5"
                onClick={() => generatePDF(admin.subscribers)}>
                Generate PDF
              </button>
            </div>
          </div>
          <div className="table-container custom-logs">
            <Styles brand={brand}>
              <Table columns={columns} data={logs} />
            </Styles>
          </div>
        </div>
      </div>
    </>
  );
};
