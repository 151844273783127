import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HeaderNav } from "../components/partials/HeaderNav";
import { NotificationBanner } from "../components/shared/warningNotification";
import { Footer } from "../components/partials/Footer";
import { Productsv2 } from "../components/shared/productsv2";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledShopPage = styled.div`
    ${props => props.brand && `
        .shop-header {
            background: ${props.brand.colors.linearBackground} !important;
            padding-top: 13rem !important;
        }
    `}
`;

const ShopCategories = styled.div`
    ${props => props.brand && `
        button.active {
            background-color:  ${props.brand.colors.accountLabel} !important;
            border:  2px solid ${props.brand.colors.accountLabel} !important;
        }
    `}
`;

export default (props) => {
    const location = useLocation();

    const auth = JSON.parse(localStorage.getItem("profile"));
    const account = useSelector((state) => state.account);
    const { brand } = account;

    const [view, setView] = useState("list");
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState("Pharmaceuticals");

    const selectedCategory = () => {
        const favBtn = document.getElementById("favorites-btn");
        const pharmaBtn = document.getElementById("pharmacy-btn");
        const animalBtn = document.getElementById("animal-btn");
        const medicalBtn = document.getElementById("medical-btn");
        const dealBtn = document.getElementById("deals-btn");
        // const smokeCessationBtn = document.getElementById("smoking-cessation-btn");
        const premierChoiceBtn = document.getElementById("premier-choice-btn");
        const yearEndSaleBtn = document.getElementById("year-end-sale-btn");
        // const specialsBtn = document.getElementById("specials-btn");
        const query = new URLSearchParams(props.location.search);
        const cat = query.get("category");
        console.log(brand);
        if (cat && cat !== "") {
            if (
                cat === "Favorites"
                && auth
            ) {
                favBtn.click();
            }

            if (
                cat === "Pharmaceuticals"
            ) {
                pharmaBtn.click();
            }

            if (
                (cat === "Animal Health" || cat === "animalhealth")
            ) {
                animalBtn.click();
            }

             if (
                (cat === "Medical Supplies" || cat === "medicalsupplies")
            ) {
                medicalBtn.click();
            }

            if (
                (cat === "Specials" || cat === "specials")
            ) {
                dealBtn.click();
            }
            // if (
            //     (brand && brand.name === 'premierpharma' && (cat === "Smoking Cessation" || cat === "smokingcessation"))
            // ) {
            //     smokeCessationBtn.click();
            // }
            if (
                (brand && brand.name === 'premierpharma' && (cat === "Premier's Choice" || cat === "premierschoice"))
            ) {
                premierChoiceBtn.click();
            }
            if (
                (brand && brand.name === 'premierpharma' && (cat === "Year End Sale" || cat === "yearendsale"))
            ) {
                yearEndSaleBtn.click();
            }
            // if (
            //   (cat === "Weekly Specials" || cat === "weeklyspecials") &&
            //   specialsBtn.className.split(" ").length === 2
            // )
            //   specialsBtn.click();
        }
        else {
            pharmaBtn.click();
        }
    };

    useEffect(() => {
        selectedCategory();
        // setIsLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, brand]);

    useEffect(() => {
        selectedCategory();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>Shop | Premier Pharmaceuticals</title>
                <link rel="icon" href={brand?.icons.favIcon} />
            </Helmet>
            <HeaderNav />
            <StyledShopPage brand={brand} className="shop-page">
                <div className="shop-header container-fluid px-4">
                    {/* {category === "Smoking Cessation"?
                    <h2>Find a great collection of 
                    <em> Smoking Cessation</em> at Premier Pharma<br />
                    Enjoy low warehouse prices on 
                    <em> Smoking Cessation</em> products.</h2> :
                        category === "Favorites" ? <h1>Revisit your saved products</h1>
                            : category === "Specials" ? <h1>Find short-dated products <br /> for a low cost</h1>
                                : category === "Pharmaceuticals" ? <h1>Shop all pharmaceuticals <br /> at a low cost</h1>
                                    : category === "Animal Health" ? <h1>Shop animal health products <br /> at a low cost</h1>
                                        : category === "Medical Supplies" ? <h1>Shop medical/surgical products <br /> at a low cost</h1>
                                            : <h1> </h1>
                    } */}
                    {category === "Premier's Choice"?
                    <h1>Shop Premier's 2023 Top 25 Best Sellers.</h1> :
                    category === "Year End Sale"?
                    <h1>Biggest sale of the year!</h1> :
                        category === "Favorites" ? <h1>Revisit your saved products</h1>
                            : category === "Specials" ? <h1>Find short-dated products <br /> for a low cost</h1>
                                : category === "Pharmaceuticals" ? <h1>Shop all pharmaceuticals <br /> at a low cost</h1>
                                    : category === "Animal Health" ? <h1>Shop animal health products <br /> at a low cost</h1>
                                        : category === "Medical Supplies" ? <h1>Shop medical/surgical products <br /> at a low cost</h1>
                                            : <h1> </h1>
                    }
                    {/* {
                    category === "Favorites" ? <h1>Revisit your saved products</h1>
                    : category === "Specials" ? <h1>Find short-dated products <br /> for a low cost</h1>
                    : category === "Pharmaceuticals" ? <h1>Shop all pharmaceuticals <br /> at a low cost</h1>
                    : category === "Animal Health" ? <h1>Shop animal health products <br /> at a low cost</h1>
                    : category === "Medical Supplies" ? <h1>Shop medical/surgical products <br /> at a low cost</h1>
                    : <h1> </h1>
                    } */}
                    <ShopCategories
                        brand={brand}
                        className="shop-categories container-fluid d-flex">
                        {
                            auth && <button id="favorites-btn"
                                className={"category-button" + (category === "Favorites" ? " active" : "")}
                                onClick={() => setCategory("Favorites")}>
                                My Favorites
                            </button>
                        }
                        <button id="deals-btn" className={"category-button" + (category === "Specials" ? " active" : "")} onClick={() => setCategory("Specials")}>
                            Short Dated
                        </button>
                        <button id="pharmacy-btn" className={"category-button" + (category === "Pharmaceuticals" ? " active" : "")} onClick={() => setCategory("Pharmaceuticals")}>
                            Pharmaceuticals
                        </button>
                        <button id="animal-btn" className={"category-button" + (category === "Animal Health" ? " active" : "")} onClick={() => setCategory("Animal Health")}>
                            Animal Health
                        </button>
                        <button id="medical-btn" className={"category-button" + (category === "Medical Supplies" ? " active" : "")} onClick={() => setCategory("Medical Supplies")}>
                            Medical/Surgical
                        </button>
                        {/* {brand && brand.name === "premierpharma" &&<button id="smoking-cessation-btn" className={"category-button" + (category === "Smoking Cessation" ? " active" : "")} onClick={() => setCategory("Smoking Cessation")}>
                            Smoking Cessation 
                        </button>} */}
                        {brand && brand.name === "premierpharma" &&<button id="premier-choice-btn" className={"category-button" + (category === "Premier's Choice" ? " active" : "")} onClick={() => setCategory("Premier's Choice")}>
                        Premier's Choice 
                        </button>}
                        {brand && brand.name === "premierpharma" &&<button id="year-end-sale-btn" className={"category-button" + (category === "Year End Sale" ? " active" : "")} onClick={() => setCategory("Year End Sale")}>
                        Year End Sale
                        </button>}
                    </ShopCategories>
                </div>

                <div className="container-fluid px-4">
                    <div className="content">
                        <NotificationBanner brand={brand} />
                        <Productsv2
                            brand={brand}
                            page="shop"
                            shopFont={true}
                            view={view}
                            setView={setView}
                            category={category}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                </div>
            </StyledShopPage>
            <Footer />
        </>
    );
};
