import React from 'react'
import "./modal.scss";
import styled from "styled-components";

const StyledMainAdmin = styled.div`
  ${props => props.brand && `
    background: ${props.brand.colors.background} !important;

    .modal-buttons .btn-success,
    .modal-buttons .sync-btn,
    .modal-buttons .login-user-btn {
      color: ${props.brand.colors.white} !important;
    }

    .modal-buttons .login-user-btn {
      background: ${props.brand.colors.theme} !important;
      border-color: ${props.brand.colors.theme} !important;
    }

  `}
`;

export const Modal = ({showModal, setShowModal, brand, user, handleEmail}) => {
  const closeModal = () => {
    setShowModal(false);
  }
  const sendEmail = () => {
    handleEmail(user);
    setShowModal(false);
  };

  return (
    <>
    {showModal &&
      <StyledMainAdmin brand={brand}>
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-header">
            <h2>User must login first. Send login reminder to user?</h2>
          </div>
          <div className="modal-buttons">
            <button className='btn btn-secondary sync-btn' onClick={closeModal}>Close</button>
            <button className='btn btn-primary login-user-btn' onClick={sendEmail}>Send Email</button>
          </div>
        </div>
      </div>
    </StyledMainAdmin>
    }
    </>
  )
}

export default Modal;