export const licensedStates = [
  { name: "Alaska" },
  { name: "Arizona" },
  { name: "California" },
  { name: "Colorado" },
  { name: "Idaho" },
  { name: "Montana" },
  { name: "Nevada" },
  { name: "New Jersey" },
  { name: "Ohio" },
  { name: "Oklahoma" },
  { name: "Oregon" },
  { name: "Pennsylvania" },
  { name: "Tennessee" },
  { name: "Texas" },
  { name: "Utah" },
  { name: "Virginia" },
  { name: "Washington" },
];
