// Auth
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const VERIFY = "VERIFY";
export const RESEND = "RESEND";

export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const CONFIRMFORGOTPASSWORD = "CONFIRMFORGOTPASSWORD";

export const GETCOUNTRIES = "GETCOUNTRIES";
export const GETSTATES = "GETSTATES";
export const GETMETHODSOFCOLLECTION = "GETMETHODSOFCOLLECTION";

// Account
export const GETACCOUNT = "GETACCOUNT";
export const GETNETSUITEACCOUNT = "GETNETSUITEACCOUNT";
export const PUTACCOUNT = "PUTACCOUNT";
export const PUTLICENSE = "PUTLICENSE";
export const UPDATEEMAIL = "UPDATEEMAIL";
export const GETORDERS = "GETORDERS";
export const GETORDER = "GETORDER";
export const GETPEDIGREE = "GETPEDIGREE";
export const CHANGEPASSWORD = "CHANGEPASSWORD";
export const ERROROLDPASS = "ERROROLDPASS";

export const SET_BRAND = "SET_BRAND";

export const GETADDRESSES = "GETADDRESSES";
export const POSTADDRESSES = "POSTADDRESSES";
export const GETADDRESSESBYID = "GETADDRESSESBYID";
export const DELETEADDRESSESBYID = "DELETEADDRESSESBYID";
export const UPDATEADDRESSESBYID = "UPDATEADDRESSESBYID";
export const UPDATEDEFAULTADDRESS = "UPDATEDEFAULTADDRESS";
export const UPDATEDEFAULTADDRESSBILLING = "UPDATEDEFAULTADDRESSBILLING";
export const ADDLICENSE = "ADDLICENSE";

// Avatar
export const GETAVATAR = "GETAVATAR";
export const POSTAVATAR = "POSTAVATAR";
export const ERRORAVATAR = "ERRORAVATAR";

// Products
export const GETPRODUCTS = "GETPRODUCTS";
export const ERRORGETPRODUCTS = "ERRORGETPRODUCTS";
export const REQUESTSTOCK = "REQUESTSTOCK";
export const REQUESTSTOCKERROR = "REQUESTSTOCKERROR";
export const GETREQUESTPRICE = "GETREQUESTPRICE";

//Search
export const GETSEARCH = "GETSEARCH";

// Cart
export const UPDATECART = "UPDATECART";
export const UPDATECOUNT = "UPDATECOUNT";
export const CHECKOUTCART = "CHECKOUTCART";
export const SETDISCOUNT = "SETDISCOUNT";
export const CLEARCART = "CLEARCART";
export const ADDDISCOUNT = "ADDDISCOUNT";
export const GETDISCOUNTS = "GETDISCOUNTS";
export const DELETEDISCOUNT = "DELETEDISCOUNT";

export const GETCARDS = "GETCARDS";
export const ADDCARD = "ADDCARD";
export const ERRORCARD = "ERRORCARD";
export const DEFAULTCARD = "DEFAULTCARD";
export const REMOVECARD = "REMOVECARD";

export const PAYMENT = "PAYMENT";
export const PAYMENTERROR = "PAYMENTERROR";

// Admin
export const GETUSERS = "GETUSERS";
export const GETUSER = "GETUSER";
export const GETADMINPRODUCTS = "GETADMINPRODUCTS";
export const GETCUSTOMPRODUCTS = "GETCUSTOMPRODUCTS";
export const RESETCUSTOMPRODUCTS = "RESETCUSTOMPRODUCTS";
export const CREATECUSTOMPRODUCT = "CREATECUSTOMPRODUCT";
export const CUSTOMPRODUCTERROR = "CUSTOMPRODUCTERROR";
export const UPDATECUSTOMPRODUCT = "UPDATECUSTOMPRODUCT";
export const REMOVECUSTOMPRODUCT = "REMOVECUSTOMPRODUCT";
export const LOGINADMINUSER = "LOGINADMINUSER";
export const LOGINERROR = "LOGINERROR";
export const LOGINADMIN = "LOGINADMIN";
export const LOGINADMINERROR = "LOGINADMINERROR";

export const CONFIRMUSER = "CONFIRMUSER";
export const CONFIRMUSERERROR = "CONFIRMUSERERROR";
export const ENABLEUSER = "ENABLEUSER";
export const ENABLEUSERERROR = "ENABLEUSERERROR";
export const DISABLEUSER = "DISABLEUSER";
export const DISABLEUSERERROR = "DISABLEUSERERROR";

export const IMPORTUSER = "IMPORTUSER";
export const IMPORTUSERERROR = "IMPORTUSERERROR";
export const EXPORTCSV = "EXPORTCSV";
export const UPDATEUSERNETSUITEID = "UPDATEUSERNETSUITEID";
export const SYNCCUSTOMPRICING = "SYNCCUSTOMPRICING";
export const SYNCCUSTOMPRICINGERROR = "SYNCCUSTOMPRICINGSYNCCUSTOMPRICINGERROR";
export const SYNCPRODUCTS = "SYNCPRODUCTS";
export const SYNCPRODUCTSERROR = "SYNCPRODUCTSERROR";
export const CREATESUBACCOUNT = "CREATESUBACCOUNT";
export const CREATESUBACCOUNTERROR = "CREATESUBACCOUNTERROR";

export const GETCUSTOMPRODUCTNETSUITE = "GETCUSTOMPRODUCTNETSUITE";
export const UPSERTCUSTOMPRODUCTNETSUITE = "UPSERTCUSTOMPRODUCTNETSUITE";
export const REMOVECUSTOMPRODUCTNETSUITE = "REMOVECUSTOMPRODUCTNETSUITE";

export const GETCUSTOMPRODUCTNETSUITEERROR = "GETCUSTOMPRODUCTNETSUITEERROR";
export const UPSERTCUSTOMPRODUCTNETSUITEERROR =
  "UPSERTCUSTOMPRODUCTNETSUITEERROR";
export const REMOVECUSTOMPRODUCTNETSUITEERROR =
  "REMOVECUSTOMPRODUCTNETSUITEERROR";

export const GETORDERLOGS = "GETORDERLOGS";
export const GETPRODUCTQUEUE = "GETPRODUCTQUEUE";
export const UPSERTPRODUCTTOQUEUE = "UPSERTPRODUCTTOQUEUE";
export const GETAUTOMATIONDATE = "GETAUTOMATIONDATE";
export const SETAUTOMATIONDATE = "SETAUTOMATIONDATE";

export const GETGROUPPRICING = "GETGROUPPRICING";
export const GETGROUPPRICINGBYID = "GETGROUPPRICINGBYID";
export const CREATEGROUPPRICING = "CREATEGROUPPRICING";
export const DELETEGROUPPRICING = "DELETEGROUPPRICING";
export const ADDGROUPPRICINGPRODUCT = "ADDGROUPPRICINGPRODUCT";
export const EDITGROUPPRICINGPRODUCT = "EDITGROUPPRICINGPRODUCT";
export const DELETEGROUPPRICINGPRODUCT = "DELETEGROUPPRICINGPRODUCT";
export const ADDGROUPPRICINGUSER = "ADDGROUPPRICINGUSER";
export const REMOVEGROUPPRICINGUSER = "REMOVEGROUPPRICINGUSER";
export const SENDEMAIL = "SENDEMAILE";
export const SENDEMAILERROR = "SENDEMAILERROR";
export const ADDSUBSCRIBER = "ADDSUBSCRIBER";

export const GLOBALINVENTORY = "GLOBALINVENTORY";
export const GLOBALINVENTORYERROR = "GLOBALINVENTORYERROR";

// Products v2
export const GETPRODUCTSV2 = "GETPRODUCTSV2";
export const GETFAVPRODUCTSV2 = "GETFAVPRODUCTSV2";
export const GETCATEGORIES = "GETCATEGORIES";

export const GETSHORTDATED = "GETSHORTDATED";

export const GETPREFERRED = "GETPREFERRED";
export const ADDPREFERRED = "ADDPREFERRED";
export const ADDPREFERREDERROR = "ADDPREFERREDERROR";
export const REMOVEPREFERRED = "REMOVEPREFERRED";
export const REMOVEPREFERREDERROR = "REMOVEPREFERREDERROR";
export const GETSUBSCRIBERS = "GETSUBSCRIBERS";

export const GETSALESPRICE ="GETSALESPRICE"
export const UPDATESALESPRICE ="UPDATESALESPRICE"
export const DELETESALESPRICE = "DELETESALESPRICE"
export const DELETESALESPRICEERROR= "DELETESALESPRICEERROR"
export const UPDATESALESPRICEERROR ="UPDATESALESPRICEERROR"
