import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { SubAccount } from "./subAccount";
import { CustomPrice } from "./customPrice";
import styled from "styled-components";

const StyledMainAdmin = styled.div`
  ${props => props.brand && `
    .header a {
      color: ${props.brand.colors.theme} !important;

    }
    .nav-tabs {
      .nav-link.active {
        color: ${props.brand.colors.admin.text} !important;
        border-color: transparent transparent ${props.brand.colors.theme} !important;
      }
    }
  `}
`;

export default (props) => {
  const { id } = useParams();
  const admin = useSelector((state) => state.admin);
  const account = useSelector((state) => state.account);
  const { brand } = account;

  const [mainCompany, setMainCompany] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const handleSetData = (users) => {
    let mainCompany = users.filter(
      (user) => user.awsNetsuiteId === id && !user.subAccount
    );
    if (!mainCompany[0]) history.push("/admin/companies");
    setMainCompany(mainCompany[0]);
  };

  useEffect(() => {
    handleSetData(admin.users);
  }, [location]);

  return (
    <Fragment>
      <Helmet>
        <title> Admin Dashboard | Premier Pharmaceuticals</title>
        <link rel="icon" href={brand?.icons.favIcon} />
      </Helmet>

      <StyledMainAdmin brand={brand} className="d-flex align-items-center justify-content-center admin-pages">
        <div className="card container">
          <div className="d-flex align-items-center justify-content-between mb-4 header">
            <Link to={`/admin/companies`}>{"< Back"}</Link>
            <h2 className="m-0">{mainCompany && `${mainCompany.company}`}</h2>
            <div></div>
          </div>

          <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#sub-accounts"
              >
                Sub Accounts
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#custom-prices">
                Custom Prices
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div className="tab-pane container active" id="sub-accounts">
              <SubAccount brand={brand} mainCompany={mainCompany} />
            </div>
            <div className="tab-pane container fade" id="custom-prices">
              <CustomPrice brand={brand} mainCompany={mainCompany} />
            </div>
          </div>
        </div>
      </StyledMainAdmin>
    </Fragment>
  );
};
