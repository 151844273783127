import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { HeaderNav } from "../components/partials/HeaderNav";
import { Footer } from "../components/partials/Footer";
import { ItemList } from "../components/cart/itemList";
import { OrderSummary } from "../components/cart/orderSummary";
import { CheckoutInfo } from "../components/cart/info";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const StyledCartHeader = styled.div`
    ${props => props.brand && `
        background: ${props.brand.colors.cartBackground} !important;
    `}
`;

const StyledCartPage = styled.div`
    ${props => props.brand && `
        .cart-progress {
            background: ${props.brand.colors.cartBackground} !important;
        }
    `}
`;

export const CheckoutContainer = () => {
    const cart = useSelector((state) => state.cart);
    const account = useSelector((state) => state.account);
    const { brand } = account;

    const [selectedShipping, setSelectedShipping] = useState(null);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const history = useHistory();

    // useEffect(()=>{
    // const user = JSON.parse(localStorage.getItem('profile'));
    // dispatch(getCart(user?.username));
    // dispatch(getCount(user?.username));
    // },[]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (cart?.cartData?.length < 1) history.push("/cart");
    }, [cart]);

    return (
        <>
            <Helmet>
                <title>Checkout | Premier Pharmaceuticals</title>
                <link rel="icon" href={brand?.icons.favIcon} />
            </Helmet>
            <HeaderNav />
            <StyledCartHeader brand={brand} className="cart-header container-fluid d-flex align-items-center justify-content-between">
                <span>Shipping Info</span>
                <Link to="/cart" className='back-btn'>
                    {"<"}
                    &nbsp;&nbsp;Back to cart
                </Link>
            </StyledCartHeader>
            <StyledCartPage brand={brand} className="container-fluid cart-page">
                <div className="main-content-container d-flex align-items-start justify-content-between">
                    <div className="cart-items-container d-flex flex-column align-items-start w-100 mb-4">
                        <div className="cart-progress d-flex align-items-center justify-content-between">
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.done} alt="" />
                                <span>Cart</span>
                            </div>
                            <div>
                                <img height={14} src={brand?.icons.cart.arrow} alt="" />
                            </div>
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step2} alt="" />
                                <span>Shipping Information</span>
                            </div>
                            <div>
                                <img height={14} src={brand?.icons.cart.arrow} alt="" />
                            </div>
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step3Next} alt="" />
                                <span>Make Payment</span>
                            </div>
                            <div>
                                <img height={14} src={brand?.icons.cart.arrow} alt="" />
                            </div>
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step4Next} alt="" />
                                <span>Complete</span>
                            </div>
                        </div>
                        <CheckoutInfo
                            brand={brand}
                            cart={cart}
                            selectedShipping={selectedShipping}
                            setSelectedShipping={setSelectedShipping}
                            selectedBilling={selectedBilling}
                            setSelectedBilling={setSelectedBilling}
                        />
                        <div className="cart-items w-100">
                            <ItemList brand={brand} cart={cart} page={"checkout"} />
                        </div>
                    </div>
                    <div className="right-container">
                        <OrderSummary
                            brand={brand}
                            page={"checkout"}
                            selectedShipping={selectedShipping}
                            selectedBilling={selectedBilling}
                            cart={cart}
                        />
                    </div>
                </div>
            </StyledCartPage>
            <Footer />
        </>
    );
};
