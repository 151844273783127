import React from 'react';
import PaymentConfirmedImg from '../assets/img/Payment/order.svg'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import styled from "styled-components";
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';

// import { clearCart } from '../actions/cart';
const StyledButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;

    a {
        margin-bottom: 1rem !important;
        width: 100% !important;
    }

    @media (min-width: 768px) { 
        flex-direction: row;

        a {
            width: auto !important;
        }
    }
`;

const StyledPaymentConfirmation = styled.div`
    ${props => props.brand && `
        h2 {
            color: ${props.brand.colors.black} !important;
        }

        .primaryButton {
            background: ${props.brand.colors.theme} !important;
            border: 2px solid ${props.brand.colors.theme} !important;

            &:hover {
                color: ${props.brand.colors.white} !important;
            }
        }

        .secondaryButton {
            border: 2px solid ${props.brand.colors.theme} !important;
            color: ${props.brand.colors.theme} !important;

            &:hover {
                background: ${props.brand.colors.cartBackground} !important;
                border: 2px solid${props.brand.colors.cartBackground} !important;
            }
        }
    `}
`;

export const PaymentConfirmationContainer = () => {
    // const payment = useSelector((state) => state.payment);
    const account = useSelector((state) => state.account);
    const { brand } = account;

    // const dispatch = useDispatch();
    // const history = useHistory();

    // useEffect(()=>{
    //     if (!payment?.paymentData) {
    //         history.push("/cart")
    //     } else {
    //         dispatch(clearCart())
    //     }
    // },[payment, history]);

    return <>
        <Helmet>
            <title>Payment Confirmation | Premier Pharmaceuticals</title>
            <link rel="icon" href={brand?.icons.favIcon} />
        </Helmet>
        <StyledPaymentConfirmation brand={brand}
            className="payment-confirmation d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={PaymentConfirmedImg} alt="" />
                <h2>Your Order is complete</h2>
                <p>We have processed your payment! Thank you for your purchase.</p>
            </div>
            <StyledButtonWrapper className="buttonWrapper d-flex align-items-center justify-content-center">
                <Link to="/account?order-history" className="primaryButton">
                    View Order
                </Link>
                <Link to="/shop" className="secondaryButton">
                    Back to browse
                </Link>
            </StyledButtonWrapper>
        </StyledPaymentConfirmation>
    </>
}