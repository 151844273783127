import React, { useState, useEffect, Fragment } from "react";
import { getGroupPricing, createGroupPricing } from "../../actions/admin";
import { useSelector, useDispatch } from "react-redux";
import { Group } from "../../components/admin/group";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const StyledCard = styled.div`
  ${props => props.brand && `
    .create-group-btn {
      background: ${props.brand.colors.theme} !important;
      border: 1px solid ${props.brand.colors.theme} !important;
    }

    .nav-link {
      color: ${props.brand.colors.black} !important;
    }

    .nav-link.active {
      background: ${props.brand.colors.theme} !important;
      color: ${props.brand.colors.white} !important;
    }
  `}
`;

export default (props) => {
  const admin = useSelector((state) => state.admin);
  const account = useSelector((state) => state.account);
  const { brand } = account;

  const [newGroupName, setNewGroupName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchGroupPricing, setSearchGroupPricing] = useState([]);
  const dispatch = useDispatch();

  const checkDuplicate = () => {
    let duplicate = null;

    if (admin.groupPricingList && newGroupName !== "") {
      duplicate = admin.groupPricingList.find(
        (group) => group.name === newGroupName
      );
    }

    return duplicate;
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    let filterProducts = [];

    if (searchValue !== "") {
      filterProducts = admin.groupPricingList.filter((product) =>
        product.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearchGroupPricing(filterProducts);
    }
    else {
      setSearchGroupPricing(admin.groupPricingList);
    }
  }, [searchValue, admin.groupPricingList]);

  useEffect(() => {
    setNewGroupName("");
    setSearchValue("");
    setSearchGroupPricing(admin.groupPricingList);
  }, [admin]);

  useEffect(() => {
    if (brand) {
      dispatch(getGroupPricing(brand.region));
    }
  }, [brand]);

  return (
    <Fragment>
      <Helmet>
        <title> Admin Dashboard | Premier Pharmaceuticals</title>
        <link rel="icon" href={brand?.icons.favIcon} />
      </Helmet>

      <StyledCard brand={brand} className="card">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h2 className="m-0">Group Pricing</h2>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="d-flex flex-column h-100">
              <div className="search-container input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Search
                  </span>
                </div>
                <input
                  className="form-control"
                  type="text"
                  name="search"
                  value={searchValue}
                  placeholder="Search group pricing options"
                  onChange={handleSearch}
                  autoComplete="off"
                />
              </div>
              <nav
                className="nav nav-pills flex-column mb-3"
                style={{
                  flex: "auto",
                  overflow: "auto",
                  minHeight: "calc(100vh - 325px)",
                  maxHeight: "calc(100vh - 325px)",
                  flexWrap: "nowrap",
                }}
              >
                {searchGroupPricing.map((group, index) => (
                  <a
                    key={`group${index}`}
                    data-toggle="pill"
                    className={"nav-link " + (index === 0 ? "active" : "")}
                    href={`#group${index}`}
                  >
                    {group.name}
                  </a>
                ))}
              </nav>
              <div className="mt-auto">
                {checkDuplicate() && (
                  <span className="text-danger">Group already exist.</span>
                )}
                <input
                  className="form-control"
                  type="text"
                  placeholder="Group name"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                />
                <button
                  className="btn btn-primary w-100 mt-3 create-group-btn"
                  disabled={newGroupName === "" || checkDuplicate()}
                  onClick={() => {
                    const newGroupData = {
                      name: newGroupName,
                      pricingList: [],
                    };
                    dispatch(createGroupPricing(newGroupData, brand.region));
                  }}
                >
                  Create Group
                </button>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="tab-content">
              {searchGroupPricing.map((group, index) => (
                <Group group={group} index={index} key={`groupData${index}`} />
              ))}
            </div>
          </div>
        </div>
      </StyledCard>
    </Fragment>
  );
};
