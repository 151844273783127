import React from "react";
// import SignupImage from "../assets/img/signup-img.png";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import MultiStepForm from "../components/signup/multi-step-form";
// import { Form } from "../components/signup/form";
import styled from "styled-components";

const SignUpBox = styled.div`
    ${props => props.brand && `
        background: url(${props.brand.icons.loginBg}) no-repeat top fixed;
        
        @media (min-width: 992px) { 
            background-size: 100%;
        }

        .login-button,
        .continueButton {
            background-color: ${props.brand.colors.theme} !important;
        }

       .signup-header a {
            color: ${props.brand.colors.theme} !important;
        }
    `}
`;

export const SignUpContainer = () => {
    const account = useSelector((state) => state.account);
    const { brand } = account;

    return <>
        <Helmet>
            <title>Sign Up | Premier Pharmaceuticals</title>
            {brand && <link rel="icon" href={brand?.icons.favIcon} />}
            {brand && <meta property="og:image" content={brand?.metaData.favicon} />}
        </Helmet>
        <SignUpBox
            brand={brand}
            className="signup container-fluid d-flex flex-column align-items-center justify-content-center">
            <div className="logo-container">
                <a href={`${brand && process.env[brand.homePageUrlKey]}`}><img height={40} src={brand?.icons.whiteLogo} alt="" /></a>
            </div>
            <div className="container d-flex align-items-center justify-content-center">
                <MultiStepForm brand={brand} />
                {/* <Form /> */}
                {/* <div className="newsletterWrapper d-flex align-items-center justify-content-center mt-5">
                    <div className="emailWrapper">
                    <h2>Want to learn more about how Premier Pharmaceuticals can help?</h2>
                    <iframe src="https://go.pardot.com/l/906322/2021-05-28/2tw2q" width="100%" type="text/html" frameborder="0" allowTransparency="true"></iframe>

                    </div>
                </div> */}
                {/* <div className="d-flex align-items-stretch emailGroup">
                        <input type="text" className="form-control " />
                        <div className="input-group-append d-flex align-items-center">
                        <button className="" type="button">Sign up</button>
                        </div>
                    </div> */}
            </div>
        </SignUpBox>
        {/* <Footer /> */}
    </>
};
