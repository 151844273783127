import React, { useState } from 'react';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { addSubscriber } from '../../actions/admin';

const FooterContainer = styled.div`
    ${props => props.brand && `
        color: ${props.brand.colors.footer.text} !important;
        background: ${props.brand.colors.footer.background} !important;

        .footer-input {
            ::placeholder {
                color: ${props.brand.colors.footer.text} !important;
                opacity: 1;
            }
        }

        button {
            background: ${props.brand.colors.theme} !important;
        }

        .footer-link,
        .social-link a {
            color: ${props.brand.colors.footer.text} !important;
        }
    `}
`;

export const Footer = () => {
    const initialState = { email: "" };
    const [errorMsg, setErrorMsg] = useState("");
    const [showError, setShowError] = useState(false);
    const account = useSelector((state) => state.account);
    const [formData, setFormData] = useState(initialState);
    const [alertType, setAlertType] = useState("error");
    const [showButton, setShowButton] = useState(false);
    const { brand } = account;
        const dispatch = useDispatch();

    const handleChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    function validateEmail(emailField) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) === false) {
            return false;
        }

        return true;

    }

    const handleSubmit = async (e) => {
        setShowButton(true);
        e.preventDefault();
        const emailCheck = validateEmail(formData.email);
        if (emailCheck) {
            const response = await dispatch(addSubscriber(formData, brand.region));
            response?.success ?
                handleAlert("Email added successfully!", "success") :
                handleAlert("Can't add to subscriber list", "error")
            setFormData(initialState);
            setShowButton(false);
        } else {
            handleAlert('Invalid Email Address', 'error');
            setFormData(initialState);
            setShowButton(false);
        }
    };

    const handleAlert = (msg, type) => {
        setErrorMsg(msg);
        setShowError(true);
        setAlertType(type);
        setTimeout(function () {
            setShowError(false);
        }, 3000);
    };

    return (
        <FooterContainer brand={brand} className="footer container-fluid d-flex flex-column">
            <span className="text-md-left text-center">Join Our Partners and get exclusive access to new collections.</span>
            <form onSubmit={handleSubmit} autocomplete="off">
                <div
                    brand={brand}
                    className="footer-input-container d-flex flex-md-row flex-column align-items-center"
                >
                    <input
                        name="email"
                        value={formData.email}
                        id="FormControlInput1"
                        className="footer-input"
                        placeholder="example@domain.com"
                        type="text"
                        onChange={handleChange}
                        autoComplete="off"
                    />
                    <button type="submit" onClick={handleSubmit} disabled={showButton}>
                        Subscribe
                    </button>
                </div>
            </form>
            <img className="footer-logo" src={brand?.icons.footerLogo} alt="Footer Logo" />
            <div className="footer-links d-flex flex-md-row flex-column align-items-center justify-content-between">
                <div className="footer-columns d-flex flex-md-row flex-column align-items-md-start align-items-center text-md-left text-center">
                    <div className="footer-column d-flex flex-column">
                        <div className="column-heading">Legal</div>
                        <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/terms-conditions`}>Terms & Conditions</a>
                        <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/terms-conditions`}>Privacy Policy</a>
                    </div>

                    <div className="footer-column d-flex flex-column">
                        <div className="column-heading">Support</div>
                        {brand && brand.name !== "premiermid-america" && <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/faq`}>FAQs</a>}
                        <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/contact-us`}>Contact Us</a>
                    </div>

                    <div className="footer-column d-flex flex-column">
                        <div className="column-heading">Company</div>
                        {brand && brand.name !== "premiermid-america" && <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/aboutus`}>About Us</a>}
                        {brand && brand.region !== "mid-america" ? <a className="footer-link" href={`${brand && process.env.REACT_APP_MID_AMERICA_HOMEPAGE_URL}`}>Premier Mid America</a> : ''}
                        <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}`}>Products</a>
                        {brand && brand.name !== "premiermid-america" && <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/consulting`}>Consulting</a>}
                        <a className="footer-link" href={`${brand && process.env[brand.homePageUrlKey]}/careers`}>Careers</a>
                    </div>
                </div>
                <div className="social-links d-flex flex-column">
                    <div className="social-link d-flex align-items-center">
                        <img src={brand?.icons.footer.facebook} alt="facebook" />
                        <a href={brand && brand.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                            Facebook
                        </a>
                    </div>
                    <div className="social-link d-flex align-items-center">
                        <img src={brand?.icons.footer.instagram} alt="instagram" />
                        <a href={brand && brand.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                            Instagram
                        </a>
                    </div>
                    <div className="social-link d-flex align-items-center">
                        <img src={brand?.icons.footer.linkedin} alt="linkedin" />
                        <a href={brand && brand.socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                            Linkedin
                        </a>
                    </div>
                </div>
            </div>
            <div
                id="toast"
                className={
                    "toast alert " +
                    (alertType === "error" ? "alert-danger " : "alert-success ") +
                    (showError ? "show" : "")
                }
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                data-delay="2000"
                style={{ position: "fixed", bottom: "1rem", right: "1rem" }}
            >
                {errorMsg}
            </div>
        </FooterContainer>
    );
};

{/* <div className="container-fluid d-flex align-items-center footer">
    <div className="container">
        <div className="d-flex justify-content-between align-items-center">
            <a href={process.env[brand.apiUrlKey]}>
                <img className="logo" src={LogoWhite} width="152.25" height="46.49" alt="logo-white" />
            </a>
            <div className="d-flex justify-content-around align-items-center">
                <a className='nav-link' href='https://www.facebook.com/PremierPharmaceuticalsLLC' target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="facebook" />
                </a>
                <a className='nav-link' href='https://www.linkedin.com/company/premier-pharmaceuticals' target="_blank" rel="noopener noreferrer">
                    <img src={Linkedin} alt="linkedin" />
                </a>
                <a className='nav-link pr-0' href='https://www.instagram.com/premierpharmaceuticals/' target="_blank" rel="noopener noreferrer">
                    <img src={Instagram} alt="instagram" />
                </a>
            </div>
        </div>
        <div className="d-flex justify-content-center align-items-center bottom-nav">
            <a className='nav-link' href={`${process.env[brand.apiUrlKey]}/terms-conditions`}>
                Privacy Policy / Terms and Conditions
            </a>
            {/* <a className='nav-link' href={`${process.env[brand.apiUrlKey]}/terms-conditions`}>
                Terms and Conditions
            </a> */}
{/*<a className='nav-link' href={`${process.env[brand.apiUrlKey]}/about-us`}>
                About Us
            </a>
        </div>
    </div>
</div> */}