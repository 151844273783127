// *******************************************************************************************
// Premier Pharma
// *******************************************************************************************
    
// Common
import HeaderLogo from "../assets/img/logo.svg";
import FooterLogo from '../assets/img/footer-logo.svg';
import DefaultCart from "../assets/icon/cart-blue.svg";
import LoginBg from "../assets/img/login-bg.png";
import WhiteLogo from "../assets/img/logo-white.svg";
import AvatarIcon from "../assets/img/Account/placeholder-dp.svg";
import CheckedIcon from "../assets/img/Checked.svg";
import UnCheckedIcon from "../assets/img/Unchecked.svg";
import BurgerMenu from "../assets/icon/burger-menu.svg";
import NoProductImage from "../assets/img/single-product-placeholder.png";
import SearchClear from "../assets/icon/search-clear.svg";
import mobileSearch from "../assets/icon/search-green-ico.svg";
import mobileSearchClose from "../assets/icon/search-close.svg";
import NoOrderProductImage from "../assets/img/unavailable.svg";
import AccountSuccess from "../assets/img/Account/register.svg";
import PasswordChangeSuccess from "../assets/img/Account/register.svg";

// Cart
import NoImage from "../assets/img/product-placeholder.png";
import NoImageColor from "../assets/img/product-placeholder-black.png"
import FavoriteOutline from "../assets/img/FavoriteOutline.svg";
import FavoriteFilled from "../assets/img/FavoriteFilled.svg";
// Sign up page
import Arrow from "../assets/img/Account/arrow.svg";
import Step1 from "../assets/img/Account/1-current.svg";
import Step2 from "../assets/img/Account/2-current.svg";
import Step2Next from "../assets/img/Account/2-next.svg";
import Step3Next from "../assets/img/Account/3-next.svg";
// Account page
import AccountUser from "../assets/img/Account/user-icon.svg";
import CartIcon from "../assets/img/Account/mdi_cart.svg";
import LogOutIcon from "../assets/img/Account/mdi_logout-variant.svg";
import SecurityIcon from "../assets/img/Account/security.svg";
import AddressIcon from "../assets/img/address-icon.svg";
import StarIcon from "../assets/img/star.svg";
import FavIcon from "../assets/img/favicon.ico";
// Cart
import CartArrow from "../assets/img/Cart/Arrow.svg"
import CartStep1 from "../assets/img/Cart/CartStep1.svg";
import CartStep2 from "../assets/img/Cart/CartStep2.svg";

import CartStep2Next from "../assets/img/Cart/CartStep2Next.svg"
import CartStep3Next from "../assets/img/Cart/CartStep3Next.svg"
import CartStep4Next from "../assets/img/Cart/CartStep4Next.svg"
import CartDone from "../assets/img/Cart/Done.svg"

import Facebook from '../assets/icon/facebook.svg';
import Instagram from '../assets/icon/instagram.svg';
import Linkedin from '../assets/icon/linkedin.svg';

// *******************************************************************************************
// Premier Mid America
// *******************************************************************************************

// Common
import MAHeaderLogo from "../assets/img/brands/mid-america/Logo.svg";
import MAFooterLogo from "../assets/img/brands/mid-america/Logo-white.svg";
import MACartIcon from "../assets/img/brands/mid-america/CartIcon.svg";
import MALoginBg from "../assets/img/brands/mid-america/login-bg.svg";
import MAWhiteLogo from "../assets/img/brands/mid-america/Logo-white.svg";
import MAStarIcon from "../assets/img/brands/mid-america/Star.svg";
import MACheckedIcon from "../assets/img/brands/mid-america/Checked.svg";
import MAUnCheckedIcon from "../assets/img/brands/mid-america/Unchecked.svg";
import MABurgerMenu from "../assets/img/brands/mid-america/MobileMenu.svg"
import SearchClearOrange from "../assets/icon/search-clear-orange.svg";
import mobileSearchOrange from "../assets/icon/search-orange-ico.svg";
import mobileSearchCloseOrange from "../assets/icon/search-close-orange.svg";
import MANoOrderProductImage from "../assets/img/brands/mid-america/product-dummy.svg";
import MAAccountSuccess from "../assets/img/brands/mid-america/register/Confirm.svg";
import MAPasswordChangeSuccess from "../assets/img/brands/mid-america/register/Confirm.svg";
// Cart
import MANoImage from "../assets/img/brands/mid-america/NoImage.svg";
import MANoImageColor from "../assets/img/brands/mid-america/NoImageBrown.svg";
import MAFavoriteOutline from "../assets/img/brands/mid-america/FavoriteOutline.svg";
import MAFavoriteFilled from "../assets/img/brands/mid-america/FavoriteFilled.svg";
// Sign up page
import MAActiveStep2 from "../assets/img/brands/mid-america/register/ActiveStep2.svg";
import MAActiveStep3 from "../assets/img/brands/mid-america/register/ActiveStep3.svg";
import MAStep2 from "../assets/img/brands/mid-america/Step-2.svg";
import MAStep3 from "../assets/img/brands/mid-america/Step-3.svg";
import MARightArrow from "../assets/img/brands/mid-america/RightArrow.svg";
import MAFavIcon from "../assets/img/brands/mid-america/favicon.ico";
// Cart
import MACartStep1 from "../assets/img/brands/mid-america/cart/Step1.svg";
import MACartStep2Next from "../assets/img/brands/mid-america/cart/Step2.svg";
import MACartStep3Next from "../assets/img/brands/mid-america/cart/Step3.svg";
import MACartStep4Next from "../assets/img/brands/mid-america/cart/Step4.svg";
import MACartArrow from "../assets/img/brands/mid-america/cart/RightArrow.svg";
import MACartDone from "../assets/img/brands/mid-america/cart/Done.svg";
import MAAddressIcon from "../assets/img/brands/mid-america/cart/Location.svg";
import MAAvatarIcon from "../assets/img/brands/mid-america/Avatar.svg";

import MAFacebook from "../assets/img/brands/mid-america/Facebook.svg";
import MAInstagram from "../assets/img/brands/mid-america/Instagram.svg";
import MALinkedIn from "../assets/img/brands/mid-america/LinkedIn.svg";


export const brands = [
    { 
        name: "premierpharma",
        region: "premier-pharma",
        apiUrlKey: "REACT_APP_BACKEND_URL",
        homePageUrlKey: "REACT_APP_HOMEPAGE_URL",
        metaData: {
            favicon: "https://premierpharma-production.s3.us-west-2.amazonaws.com/icons/favicon.ico",
            webFlowDPUrl: "https://premierpharma-production.s3.us-west-2.amazonaws.com/icons/premier-pharma-dp.svg"
        },
        socialLinks: {
            facebook: "https://www.facebook.com/people/Premier-Pharma/100092650552829/?is_tour_completed=true",
            instagram: "https://www.instagram.com/premierpharma_/",
            linkedin: "https://www.linkedin.com/company/premier-pharmaceuticals"
        },
        colors: {
            linearBackground: "linear-gradient(180deg, #CCDCFF 0%, rgba(204, 220, 255, 0) 100%)",
            headerScrolled: "",
            shippingCounter: {
                textColor: "#0D1834",
                background: "#C2D5FF",
                percent: "#054FF0",
                progress: "#ACC5FD",
                price: {
                    color: "#054FF0",
                    background: "#FFFFFF"
                }

            },
            myAccount: {
                text: "#054FF0"
            },
            product: {
                detail: "#FFFFFF"
            },
            background: "#F6F9FE",
            theme: "#054FF0",
            black: "#181818",
            supporting: "#CED9DB",
            cartCount: "#FC4A4A",
            accountSideNav: "#054FF0",
            accountLabel: "#054FF0",
            white: "#FFFFFF",
            cartBackground: "#0D1834",
            productPrice: "#054FF0",
            disable: {
                background: "#CCC",
                text: "#666"
            },
            footer: {
                text: "#F6F5F3",
                background: "#0D1834"
            },
            admin: {
                text: "#95aac9",
                tableBackground: "#3b506c",
                tableBorder: "#496285"
            },
            notFoundText: "#0098FF"
        },
        icons: {
            favIcon: FavIcon,
            avatar: AvatarIcon,
            headerLogo: HeaderLogo,
            footerLogo: FooterLogo,
            cartIcon: DefaultCart,
            loginBg: LoginBg,
            whiteLogo: WhiteLogo,
            noImage: NoImage,
            noImageColor: NoImageColor,
            favoriteOutline: FavoriteOutline,
            favoriteFilled: FavoriteFilled,
            checked: CheckedIcon,
            unChecked: UnCheckedIcon,
            mobileMenu: BurgerMenu,
            noProductImage: NoProductImage,
            mobileSearch: mobileSearch,
            mobileSearchClose: mobileSearchClose,
            SearchClear: SearchClear,
            ForgotPassword: PasswordChangeSuccess,
            signup: {
                arrow: Arrow,
                step1: Step1,
                step2: Step2,
                step3: Step3Next,
                step2Next: Step2Next,
                step3Next: Step3Next,
                confirm: AccountSuccess 
            },
            account: {
                user: AccountUser,
                cart: CartIcon,
                logout: LogOutIcon,
                security: SecurityIcon,
                address: AddressIcon,
                star: StarIcon,
                noProductImage: NoOrderProductImage
            },
            cart: {
                arrow: CartArrow,
                step1: CartStep1,
                step2: CartStep2,
                step2Next: CartStep2Next,
                step3Next: CartStep3Next,
                step4Next: CartStep4Next,
                done: CartDone
            },
            footer: {
                facebook: Facebook,
                instagram: Instagram,
                linkedin: Linkedin
            }
        },
        classNames: {
            productDetails: {
                image: "premier-pharma-image"
            }
        }
    },
    {
        name: "premiermid-america",
        region: "mid-america",
        apiUrlKey: "REACT_APP_MID_AMERICA_BACKEND_URL",
        homePageUrlKey: "REACT_APP_MID_AMERICA_HOMEPAGE_URL",
        metaData: {
            favicon: "https://premierpharma-production.s3.us-west-2.amazonaws.com/icons/midamerica-favicon.ico",
            webFlowDPUrl: "https://premierpharma-production.s3.us-west-2.amazonaws.com/icons/mid-america-dp.svg"
        },
        socialLinks: {
            facebook: "https://www.facebook.com/premiermidamerica",
            instagram: "https://www.instagram.com/premiermidamerica/",
            linkedin: "https://www.linkedin.com/company/premier-mid-america/"
        },
        colors: {
            linearBackground: "linear-gradient(180deg, #FFF8E7 0%, rgba(244, 233, 206, 0) 100%)",
            headerScrolled: "#F4E9CE",
            shippingCounter: {
                textColor: "#181818",
                background: "#F6F5F3",
                percent: "#3A2822",
                progress: "#EFEADD",
                price: {
                    color: "#F4E9CE",
                    background: "#FB4F14"
                }
            },
            myAccount: {
                text: "#181818"
            },
            product: {
                detail: "#EFEADD"
            },
            background: "#F6F6F6",
            theme: "#FB4F14",
            black: "#181818",
            supporting: "#F4E9CE",
            cartCount: "#181818",
            accountSideNav: "#181818",
            accountLabel: "#181818",
            white: "#FFFFFF",
            cartBackground: "#181818",
            productPrice: "#181818",
            disable: {
                background: "#CCC",
                text: "#666"
            },
            footer: {
                text: "#F4E9CE",
                background: "#181818"
            },
            admin: {
                text: "#181818",
                tableBackground: "#181818",
                tableBorder: "#181818"
            },
            notFoundText: "#181818"
        },
        icons: {
            favIcon: MAFavIcon,
            avatar: MAAvatarIcon,
            headerLogo: MAHeaderLogo,
            footerLogo: MAFooterLogo,
            cartIcon: MACartIcon,
            loginBg: MALoginBg,
            whiteLogo: MAWhiteLogo,
            noImage: MANoImage,
            noImageColor: MANoImageColor,
            favoriteOutline: MAFavoriteOutline,
            favoriteFilled: MAFavoriteFilled,
            checked: MACheckedIcon,
            unChecked: MAUnCheckedIcon,
            mobileMenu: MABurgerMenu,
            noProductImage: MANoImage,
            mobileSearch: mobileSearchOrange,
            mobileSearchClose: mobileSearchCloseOrange,
            SearchClear: SearchClearOrange,
            ForgotPassword: MAPasswordChangeSuccess,
            signup: {
                arrow: MARightArrow,
                step1: Step1,
                step2: MAActiveStep2,
                step3: MAActiveStep3,
                step2Next: MAStep2,
                step3Next: MAStep3,
                confirm: MAAccountSuccess
            },
            account: {
                user: AccountUser,
                cart: CartIcon,
                logout: LogOutIcon,
                security: SecurityIcon,
                address: MAAddressIcon,
                star: MAStarIcon,
                noProductImage: MANoOrderProductImage
            },
            cart: {
                arrow: MACartArrow,
                step1: MACartStep1,
                step2: MACartStep2Next,
                step2Next: MACartStep2Next,
                step3Next: MACartStep3Next,
                step4Next: MACartStep4Next,
                done: MACartDone
            },
            footer: {
                facebook: MAFacebook,
                instagram: MAInstagram,
                linkedin: MALinkedIn
            }
        },
        classNames: {
            productDetails: {
                image: "mid-america-image"
            }
        }
    }
];
