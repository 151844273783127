import React, { useState, useEffect, useCallback } from 'react';

import {
    formatPhoneNumberIntl,
    isPossiblePhoneNumber,
} from "react-phone-number-input";
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import Input from "../../shared/input";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { addLicense } from '../../../actions/auth';


export default function Licensing ({ formData, setFormData, navigation, onChange }) {
    const [isDisabled, setDisabled] = useState(false);
    const [ isUploaded, setUploaded] = useState(false);
    const [alertType, setAlertType] = useState("error");
    const [errorMsg, setErrorMsg] = useState("");
    const [showError, setShowError] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState();
    const { next } = navigation;
    const dispatch = useDispatch();


    const handleAlert = (msg, type) => {
        setErrorMsg(msg);
        setShowError(true);
        setAlertType(type);
        setTimeout(function () {
          setShowError(false);
        }, 3000);
      };

    const validation = useCallback(() => {
		// const phoneCheck = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phoneNumber);
		const phoneCheck =
			formatPhoneNumberIntl(formData.phoneNumber) &&
			isPossiblePhoneNumber(formData.phoneNumber)
				? true
				: false;

		formData.givenName &&
		formData.familyName &&
		formData.phoneNumber &&
		phoneCheck &&
		formData.company &&
		formData.address &&
		formData.city &&
		formData.postalCode &&
		formData.country &&
		formData.state &&
		formData.apEmail &&
		formData.apContact &&
		formData.apPhone &&
		formData.methodOfCollection &&
        formData.stateLicenseNumber &&
        formData.stateLicenseExpirationDate &&
        isUploaded
			? setDisabled(false)
			: setDisabled(true);
	}, [formData, isUploaded]);

    const [touchedFields, setTouchedFields] = useState({
        stateLicenseNumber: false,
        stateLicenseExpirationDate: false,
      });

      const handleFieldTouch = (fieldName) => {
        setTouchedFields((prevTouchedFields) => ({
          ...prevTouchedFields,
          [fieldName]: true,
        }));
      };
      
      const successTrue = () => {
        setIsUploading(false);
        setUploaded(true);
        handleAlert("Uploaded Successfully!", "success") 
      }
      const successFalse = () =>{
        setIsUploading(false);
        setUploaded(false)
        handleAlert("Please add a correct file", "error")
      }
      const uploadImage = async() => {
        setIsUploading(true);
        if (file) {
          const data = new FormData();
          data.append("file", file);
          data.append("username", formData.apEmail);
          const response = await dispatch(addLicense(data, formData.apEmail));
          response?.success ?
           successTrue()
            :  successFalse()
        } else {
          successFalse();
        }
       };
     

    useEffect(() => {
        validation();
    }, [validation]);


  // Disable dates before the current date
  const disabledDate = (current) => {
    // Get the current date
    const currentDate = new Date();

    // Disable if the current date is after the selected date
    return current && current < moment(currentDate, "YYYY-MM-DD");
  };

    return <>
    <div className="card mb-0">
        <div className="signup-header d-flex flex-column align-items-center justify-content-center">
            <h2 className="mb-0">Licensing</h2>
        </div>
        <div className="licensing-info d-flex flex-column">
            <div className="input-wrapper">
                <label>State License</label>
                <Input
                    type="text"
                    name="stateLicenseNumber"
                    label="Enter State License Number"
                    value={formData.stateLicenseNumber}
                    onChange={onChange}
                    required
                    onBlur={() => handleFieldTouch('stateLicenseNumber')}
                />
            </div>
            {!formData.stateLicenseNumber && touchedFields.stateLicenseNumber && <p className='validation-message' >* Payable contact is required</p>}
            <div className="input-wrapper">
                <label>State License Expiry Date</label>
                <div className='form-group'>
                <Flatpickr
                    name="stateLicenseExpirationDate"
                    className="form-control"
                    value={formData.stateLicenseExpirationDate}
                    placeholder="State License Expiry Date"
                    options={{ minDate: "today" }}
                    onChange={([date]) => {
                        setFormData({
                            ...formData,
                            stateLicenseExpirationDate: date
                        });
                    }}
                    disable={disabledDate}
                    onBlur={() => handleFieldTouch('stateLicenseExpirationDate')}
                />
                </div>
            {!formData.stateLicenseExpirationDate && touchedFields.stateLicenseExpirationDate && <p className='validation-message'>* Payable contact is required</p>}
            </div>
            <div className="input-wrapper">
                <label>Drug Enforcement Administration</label>
                <Input
                    type="text"
                    name="dea"
                    label="DEA (optional)"
                    value={formData.dea}
                    onChange={onChange}
                />
            </div>
            <div className="input-wrapper">
                <label>DEA Expiry Date</label>
                <Flatpickr
                    name="deaExpiry"
                    className="form-control"
                    value={formData.deaExpiry}
                    placeholder="DEA Expiry Date"
                    options={{ minDate: "today" }}
                    onChange={([date]) => {
                        setFormData({
                            ...formData,
                            deaExpiry: date
                        });
                    }}
                    disable={disabledDate}
                />
            </div>
            <div className="input-wrapper">
                <label  style={{marginTop: '20px'}}>Upload License</label>
           <input
                name="licenseFile"
                type="file"
                className="custom-file-upload" 
                onChange={(e) => setFile(e.target.files[0])} />
            <button className="next-btn" onClick={uploadImage} disabled={isUploaded}>
            {isUploading ? (
                <div
                  className="spinner-border text-light spinner-border-sm"
                  role="sync"
                >
                  <span className="btn btn-primary w-25 sr-only">Uploading...</span>
                </div>
              ) : (
                "Upload License"
              )}
            </button>
            </div>
        </div>
        <button className="next-btn" onClick={next} disabled={isDisabled}>
            <span>Proceed to Last Step</span>
        </button>
    </div>
    <div
            id="toast"
            className={
              "toast alert " +
              (alertType === "error" ? "alert-danger " : "alert-success ") +
              (showError ? "show" : "")
            }
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-delay="2000"
            style={{ position: "fixed", bottom: "1rem", right: "1rem" }}
          >
            {errorMsg}
          </div>
    </>
}
