import {
  GETUSERS,
  GETUSER,
  GETCUSTOMPRODUCTS,
  RESETCUSTOMPRODUCTS,
  CREATECUSTOMPRODUCT,
  CUSTOMPRODUCTERROR,
  UPDATECUSTOMPRODUCT,
  REMOVECUSTOMPRODUCT,
  LOGINADMINUSER,
  LOGINERROR,
  LOGINADMIN,
  LOGINADMINERROR,
  CONFIRMUSER,
  CONFIRMUSERERROR,
  ENABLEUSER,
  ENABLEUSERERROR,
  DISABLEUSER,
  DISABLEUSERERROR,
  IMPORTUSER,
  IMPORTUSERERROR,
  EXPORTCSV,
  UPDATEUSERNETSUITEID,
  SYNCCUSTOMPRICING,
  SYNCCUSTOMPRICINGERROR,
  SYNCPRODUCTS,
  SYNCPRODUCTSERROR,
  CREATESUBACCOUNT,
  CREATESUBACCOUNTERROR,
  GETCUSTOMPRODUCTNETSUITE,
  UPSERTCUSTOMPRODUCTNETSUITE,
  REMOVECUSTOMPRODUCTNETSUITE,
  UPSERTCUSTOMPRODUCTNETSUITEERROR,
  REMOVECUSTOMPRODUCTNETSUITEERROR,
  GETORDERLOGS,
  GETPRODUCTQUEUE,
  UPSERTPRODUCTTOQUEUE,
  GETAUTOMATIONDATE,
  SETAUTOMATIONDATE,
  GETGROUPPRICING,
  GETGROUPPRICINGBYID,
  CREATEGROUPPRICING,
  DELETEGROUPPRICING,
  ADDGROUPPRICINGPRODUCT,
  EDITGROUPPRICINGPRODUCT,
  DELETEGROUPPRICINGPRODUCT,
  ADDGROUPPRICINGUSER,
  REMOVEGROUPPRICINGUSER,
  SENDEMAIL,
  SENDEMAILERROR,
  ADDSUBSCRIBER,
  GETSUBSCRIBERS,
  ADDDISCOUNT,
  GETDISCOUNTS,
  DELETEDISCOUNT,
  GLOBALINVENTORY,
  GLOBALINVENTORYERROR
} from "../constants/actionTypes";

import * as api from "../api/index.js";

export const getUsers = (region) => async (dispatch) => {
  try {
    const { data } = await api.getUsers(region);

    dispatch({ type: GETUSERS, data });
  } catch (error) {
    console.log(error);
  }
};

export const getUser = (user, region) => async (dispatch) => {
  try {
    const { data: userInfo } = await api.getAccount(user.username, region);

    const newData = { ...user, information: userInfo };

    dispatch({ type: GETUSER, data: newData });
  } catch (error) {
    console.log(error);
  }
};

export const getCustomProducts = (username, region) => async (dispatch) => {
  try {
    const { data } = await api.getCustomProducts(username, region);

    dispatch({ type: GETCUSTOMPRODUCTS, data });
  } catch (error) {
    console.log(error);
  }
};

export const resetCustomProducts = () => async (dispatch) => {
  try {
    dispatch({ type: RESETCUSTOMPRODUCTS });
  } catch (error) {
    console.log(error);
  }
};

export const createCustomProduct = (formData) => async (dispatch) => {
  try {
    const { data } = await api.createCustomProduct(formData);

    dispatch({ type: CREATECUSTOMPRODUCT, data });
  } catch (error) {
    const data = error.response.data;

    dispatch({ type: CUSTOMPRODUCTERROR, data });
  }
};

export const updateCustomProduct = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.updateCustomProduct(id, formData);

    dispatch({ type: UPDATECUSTOMPRODUCT, data });
  } catch (error) {
    // console.log(error);
    const data = error.response.data;

    dispatch({ type: CUSTOMPRODUCTERROR, data });
  }
};

export const removeCustomProduct = (id) => async (dispatch) => {
  try {
    await api.removeCustomProduct(id);

    dispatch({ type: REMOVECUSTOMPRODUCT, id });
  } catch (error) {
    console.log(error);
  }
};

export const loginAdminUser = (formData, profile, region) => async (dispatch) => {
  try {
    if (profile) {
      await api.logOut(profile.username, region);
      localStorage.removeItem("profile");
    }

    const { data } = await api.loginAdminUser(formData, region);

    dispatch({ type: LOGINADMINUSER, data });

    window.open("/shop", "_blank");
  } catch (error) {
    dispatch({ type: LOGINERROR });
  }
};

export const loginAdmin = (formData, route, region) => async (dispatch) => {
  try {
    const { data } = await api.loginAdmin(formData, region);

    dispatch({ type: LOGINADMIN, data });

    route.push("/admin");
  } catch (error) {
    dispatch({
      type: LOGINADMINERROR,
      data: { message: "Login Error. Try again later!" },
    });
  }
};

export const confirmUser = (formData, user, region) => async (dispatch) => {
  try {
    await api.confirmUser(formData, region);

    const data = { ...user, UserStatus: "CONFIRMED", status: "CONFIRMED" };

    dispatch({ type: CONFIRMUSER, data });
  } catch (error) {
    const data = { success: false };
    dispatch({ type: CONFIRMUSERERROR, data });
  }
};

export const enableUser = (user, region) => async (dispatch) => {
  try {
    await api.enableUser(user.username, region);

    const data = { ...user, isEnabled: true };

    dispatch({ type: ENABLEUSER, data });
  } catch (error) {
    const data = { success: false };
    dispatch({ type: ENABLEUSERERROR, data });
  }
};

export const disableUser = (user, region) => async (dispatch) => {
  try {
    await api.disableUser(user.username, region);

    const data = { ...user, isEnabled: false };

    dispatch({ type: DISABLEUSER, data });
  } catch (error) {
    const data = { success: false };
    dispatch({ type: DISABLEUSERERROR, data });
  }
};

export const setGlobalInventory = (user,isEnabled, region) => async (dispatch) => {
  try {
    const response = await api.setInventoryAPI(user.username, isEnabled, region);
    if ( response?.data?.success ) {
    const data = { ...user, globalInventory: isEnabled };

    dispatch({ type: GLOBALINVENTORY, data })} else {
      const data = { success: false };
      dispatch({ type: GLOBALINVENTORYERROR, data });

    }
    return response;
  } catch (error) {
    const data = { success: false };
    dispatch({ type: GLOBALINVENTORYERROR, data });
  }
};

export const importUser = (formData) => async (dispatch) => {
  try {
    const { data } = await api.importUser(formData);

    const newData = {
      Username: data.userSub,
      Attributes: [
        {
          Name: "sub",
          Value: data.userSub,
        },
        {
          Name: "email_verified",
          Value: "false",
        },
        {
          Name: "phone_number_verified",
          Value: "false",
        },
        {
          Name: "phone_number",
          Value: formData.phoneNumber,
        },
        {
          Name: "given_name",
          Value: formData.givenName,
        },
        {
          Name: "family_name",
          Value: formData.familyName,
        },
        {
          Name: "email",
          Value: formData.email,
        },
        {
          Name: "custom:company",
          Value: formData.company,
        },
      ],
      Enabled: true,
      UserStatus: "UNCONFIRMED",
    };

    dispatch({ type: IMPORTUSER, data: newData });
  } catch (error) {
    const { data } = error.response;
    dispatch({ type: IMPORTUSERERROR, data });
  }
};

export const exportCSV = () => async (dispatch) => {
  try {
    const { data } = await api.exportCSV();

    dispatch({ type: EXPORTCSV, data });
  } catch (error) {
    dispatch({ type: EXPORTCSV, data: [] });
  }
};

export const updateUserNetsuiteID =
  (username, formData, region) => async (dispatch) => {
    try {
      const { data } = await api.updateUserNetsuiteID(username, formData, region);

      dispatch({ type: UPDATEUSERNETSUITEID, data });
    } catch (error) {
      console.log("Error Update User Netsuite ID");
    }
  };

export const syncCustomPricing = (username, region) => async (dispatch) => {
  try {
    const { data } = await api.syncCustomPricing(username, region);

    dispatch({ type: SYNCCUSTOMPRICING, data });
  } catch (error) {
    dispatch({
      type: SYNCCUSTOMPRICINGERROR,
      data: "Error syncing custom pricing!",
    });
  }
};

export const sendEmail = (username, region) => async (dispatch) => {
  try {
    const { data } = await api.sendEmailToUser(username, region);

    dispatch({ type: SENDEMAIL, data });
    return data;
  } catch (error) {
    dispatch({
      type: SENDEMAILERROR,
      data: "Error sending email!",
    });
  }
};

export const syncProducts = (region) => async (dispatch) => {
  try {
    const { data } = await api.syncProducts(region);

    dispatch({ type: SYNCPRODUCTS, data });
  } catch (error) {
    dispatch({ type: SYNCPRODUCTSERROR, data: "Error syncing products!" });
  }
};

export const createSubAccount = (formData, region) => async (dispatch) => {
  try {
    const { data } = await api.createSubAccount(formData, region);

    const newData = {
      username: data.userSub,
      email: formData.email,
      company: formData.company,
      status: "UNCONFIRMED",
      subAccount: true,
      netsuiteId: "",
      customerId: "",
      awsNetsuiteId: formData.netsuiteCustomerId,
    };

    dispatch({ type: CREATESUBACCOUNT, data: newData });
  } catch (error) {
    const { data } = error.response;

    dispatch({ type: CREATESUBACCOUNTERROR, data });
  }
};

export const getCustomProjectsNetsuite = (username, region) => async (dispatch) => {
  try {
    const { data } = await api.getCustomProjectsNetsuite(username, region);

    dispatch({ type: GETCUSTOMPRODUCTNETSUITE, data });
  } catch (error) {
    console.log(error);
  }
};

export const upsertCustomProjectsNetsuite =
  (username, formData, region) => async (dispatch) => {
    try {
      await api.upsertCustomProjectsNetsuite(username, formData, region);

      dispatch({ type: UPSERTCUSTOMPRODUCTNETSUITE, data: formData });
    } catch (error) {
      const data = error.response.data;

      dispatch({ type: UPSERTCUSTOMPRODUCTNETSUITEERROR, data });
    }
  };

export const removeCustomProjectsNetsuite =
  (username, formData, region) => async (dispatch) => {
    try {
      await api.removeCustomProjectsNetsuite(username, formData, region);

      dispatch({ type: REMOVECUSTOMPRODUCTNETSUITE, data: formData });
    } catch (error) {
      const data = error.response.data;
      dispatch({ type: REMOVECUSTOMPRODUCTNETSUITEERROR, data });
    }
  };

export const getOrderLogs = (region) => async (dispatch) => {
  try {
    const { data } = await api.getOrderLogs(region);

    dispatch({ type: GETORDERLOGS, data });
  } catch (error) {
    console.log(error);
  }
};

export const getProductQueue = (region) => async (dispatch) => {
  try {
    const { data } = await api.getProductQueue(region);

    dispatch({ type: GETPRODUCTQUEUE, data });
  } catch (error) {
    console.log(error);
  }
};

export const upsertProductToQueue = (product, region) => async (dispatch) => {
  try {
    const { data } = await api.upsertProductToQueue(product, region);

    dispatch({ type: UPSERTPRODUCTTOQUEUE, data });
  } catch (error) {
    console.log(error);
  }
};

export const getAutomationDate = (region) => async (dispatch) => {
  try {
    const { data } = await api.getAutomationDate(region);

    dispatch({ type: GETAUTOMATIONDATE, data });
  } catch (error) {
    console.log(error);
  }
};

export const setAutomationDate = (automationDate, region) => async (dispatch) => {
  try {
    const { data } = await api.setAutomationDate(automationDate, region);

    dispatch({ type: SETAUTOMATIONDATE, data });
  } catch (error) {
    console.log(error);
  }
};

export const getGroupPricing = (region) => async (dispatch) => {
  try {
    const { data } = await api.getGroupPricing(region);

    dispatch({ type: GETGROUPPRICING, data });
  } catch (error) {
    console.log(error);
  }
};

export const createGroupPricing = (formData, region) => async (dispatch) => {
  try {
    const { data } = await api.createGroupPricing(formData, region);

    dispatch({ type: CREATEGROUPPRICING, data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGroupPricing = (groupPricingId, region) => async (dispatch) => {
  try {
    await api.deleteGroupPricing(groupPricingId, region)

    dispatch({
      type: DELETEGROUPPRICING,
      data: { groupPricingId },
    });
  }
  catch (error) {
    console.log(error)
  }
}

export const addGroupPricingProduct =
  (groupPricingId, formData, region) => async (dispatch) => {
    try {
      await api.addGroupPricingProduct(groupPricingId, formData, region);

      dispatch({
        type: ADDGROUPPRICINGPRODUCT,
        data: { groupPricingId, product: formData },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const editGroupPricingProduct =
  (groupPricingId, productId, formData, region) => async (dispatch) => {
    try {
      await api.editGroupPricingProduct(groupPricingId, productId, {
        price: formData.price,
        markupPercentage: formData.markupPercentage,
        lastPurchasePrice: (formData.lastPurchasePrice).toString(),
      }, region);

      const {data } = await api.getGroupPricingById(groupPricingId, region);
      dispatch({
        type: EDITGROUPPRICINGPRODUCT,
        data: { groupPricingId, productId, product: formData },
      });
      dispatch({ type: GETGROUPPRICINGBYID, data });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteGroupPricingProduct =
  (groupPricingId, productId, region) => async (dispatch) => {
    try {
      await api.deleteGroupPricingProduct(groupPricingId, productId, region);

      dispatch({
        type: DELETEGROUPPRICINGPRODUCT,
        data: { groupPricingId, productId },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const addGroupPricingUser =
  (user, groupPricingId, region) => async (dispatch) => {
    try {
      await api.addGroupPricingUser(user.username, { groupPricingId }, region);

      dispatch({
        type: ADDGROUPPRICINGUSER,
        data: { user, groupPricingId },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const removeGroupPricingUser =
  (user, groupPricingId, region) => async (dispatch) => {
    try {
      await api.removeGroupPricingUser(user.username, { groupPricingId }, region);

      dispatch({
        type: REMOVEGROUPPRICINGUSER,
        data: { user, groupPricingId },
      });
    } catch (error) {
      console.log(error);
    }
  };

  export const addSubscriber = (formData, region) => async (dispatch) => {
    try {
      const { data } = await api.addNewSubscriber(formData.email, region);
      dispatch({ type: ADDSUBSCRIBER, data });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  export const getSubscribers = (region) => async (dispatch) => {
    try {
      const { data } = await api.getAllSubscribers(region);
       const response = data?.data
      dispatch({ type: GETSUBSCRIBERS, response });
    } catch (error) {
      console.log(error);
    }
  };

  export const addCoupon = (formData) => async (dispatch) => {
    try {
      const { data } = await api.addDiscount(formData);
     if (data.success) {
      dispatch({ type: ADDDISCOUNT, payload: data }) } else {
      return data
      }
    } catch (error) {
      const data = {'valid': false, 'error': true};
  
      dispatch({ type: ADDDISCOUNT, payload: data  });
    }
  };
  
  export const getCoupons = () => async (dispatch) => {
    try {
      const { data } = await api.getDiscounts();

      dispatch({ type: GETDISCOUNTS, payload: data });
      
    } catch (error) {
      const data = {'valid': false, 'error': true};
  
      dispatch({ type: GETDISCOUNTS, payload: data  });
    }
  };

  export const deleteCoupon = (id) => async (dispatch) => {

    try {
      const { data } = await api.deleteCoupon(id);
  
      dispatch({ type: DELETEDISCOUNT, payload: data });
      
    } catch (error) {
      const data = {'valid': false, 'error': true};
  
      dispatch({ type: DELETEDISCOUNT, payload: data  });
    }
  };