import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getOrders, getOrder, getPedigree } from "../../actions/account";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import jsPDF from "jspdf";
import premierPharmaLogo from '../../../src/assets/img/logo.png';
import midAmericaLogo from '../../../src/assets/img/Mid-America-logo.png';

const StyledLoadMoreButton = styled.button`
  ${(props) =>
    props.brand &&
    `
        color: ${props.brand.colors.theme} !important;
        border: 1px solid ${props.brand.colors.theme} !important;
    `}
`;

const Styles = styled.div`
    padding: 1rem;

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            },
        }

        th, td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
    }

    ${(props) =>
    props.brand &&
    `
      .table .thead-dark th {
        background-color: ${props.brand.colors.black} !important;
        border-color: ${props.brand.colors.black} !important;
      }
    `}
`;

export const OrdersHistory = () => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile"));
  const account = useSelector((state) => state.account);
  const { brand } = account;

  const [orders, setOrders] = useState([]);
  const totalPageCount = Math.ceil(account?.ordersData?.count / 5);
  const [status, setStatus] = useState("All");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState("");
  const [getLoading, setGetLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("order");
  const [monthsAgo, setMonthsAgo] = useState(1);
  const [delayTimer, setDelayTimer] = useState(null);
  const [disable, setDisable] = useState(false);
  const itemsPerPage = 5;

  const formatDate = (ms) => {
    const date = new Date(ms);
    return date.toLocaleString("en-US", {
      month: "long", // numeric, 2-digit, long, short, narrow
      day: "2-digit", // numeric, 2-digit
      year: "numeric", // numeric, 2-digit
    });
  };

  const displayedItems = orders.slice(
    (page-1)* itemsPerPage,
    ((page-1) + 1) * itemsPerPage
  );

  const calcSubTotal = (order) => {
    let items = order.details.items;
    let subTotal = 0;

    for (let i = 0; i < items.length; i++) {
      let price = items[i].price;
      let quantity = items[i].quantity;
      subTotal += price * quantity;
    }

    return subTotal.toFixed(2);
  };

  const formatShipping = (text) => {
    return <span> {text.replaceAll("\n", ", ")}</span>;
  };

  const addRow = (
    x,
    y,
    rowHeight,
    maxPageHeight,
    pdf,
    colWidth,
    label,
    value,
    check = true
  ) => {
    if (y + 6 * rowHeight > maxPageHeight) {
      pdf.addPage();
      y = 20;
    }

    pdf.setFont("helvetica", "bold");
    pdf.text(x, y, label);
    pdf.setFont("helvetica", "normal");
    pdf.text(x + colWidth, y, value);

    return check ? (y += rowHeight) : y;
  };

  const generatePDF = (order) => {
    const pdf = new jsPDF();
    const shippingAddress = order.details.shippingAddress.replaceAll(
      "\n",
      ", "
    );
    const totalAmount =
      parseFloat(calcSubTotal(order)) +
      parseFloat(order.details.shippingFee.toFixed(2));
    let x = 10;
    let y = 20;
    const rowHeight = 10;
    const colWidth = 60;
    const maxPageHeight = 350;
    let serialNumber = 1;
    const orders = order.details.items;

    pdf.setFont("helvetica", "bold");
    pdf.text(10, 10, "List of orders");
    pdf.setFont("helvetica", "normal");
    y += rowHeight;

    // adding logo to the pdf
    const image =
      brand && brand.name === "premierpharma"
        ? premierPharmaLogo
        : midAmericaLogo;
    const imageWidth = 40;
    const imageHeight = 10;
    pdf.addImage(image, "PNG", x + 150, 4, imageWidth, imageHeight);

    orders.forEach((obj) => {
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Serial No:", serialNumber.toString());
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Product Name:", obj.productName);
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Product NDC:", obj.ndc);
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Product Price:", obj.price.toString());
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Product Quantity:", obj.quantity.toString());
      serialNumber++;
      pdf.text(x + colWidth, y, "____________________________");
      y += 2 * rowHeight;
    });

    const shippingAddressLines = pdf.splitTextToSize(shippingAddress, 120);
    y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Shipping Address:", "", false);
    shippingAddressLines.forEach((line) => {
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "", line);
    });

    if (order.details.items.length > 0) {
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Subtotal:", `$${calcSubTotal(order)}`);
    }
    if (order.details.shippingFee >= 0) {
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Shipping Fee:", `$${order.details.shippingFee.toFixed(2)}`);
    }
    if (order.details.discount > 0) {
      y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Discount:", `$${order.details.discount.toFixed(2)}`);
    }
    y = addRow(x, y, rowHeight, maxPageHeight, pdf, colWidth, "Total:", `$${totalAmount}`);
    pdf.save("orders.pdf");
  };

  const renderOrder = (order, index) => {
    const pedigreeList = order?.pedigrees;
    return (
      <div key={`key-${index}`} className="card order">
        <div className="date-status-container d-flex align-items-center justify-content-between">
          <div className="order-number-container d-flex flex-row align-items-center">
            <p className="order-number d-flex m-0">
              Order #{order.salesOrderNumber}
            </p>
            {order.trackingNumber && (
              <p>
                Tracking{" "}
                <a
                  href={`https://www.fedex.com/fedextrack/?trknbr=${order.trackingNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  #{order.trackingNumber}
                </a>
              </p>
            )}
            <p className="date-ordered m-0">
              {formatDate(order.details.dateOrdered)}
            </p>
          </div>
          <div
            className={`status ${order.details.shipComplete ? "shipped" : "processing"
              }`}
          >
            {order.details.shipComplete ? "Shipped" : "Processing"}
          </div>
        </div>

        {order.details.items.length > 0 && status !== "Pedigree" && (
          <div className="accordion" id={`detailsContainer${index}`}>
            <div className="order-details card">
              <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#details${index}`}
                    aria-expanded="true"
                    aria-controls={`details${index}`}
                  >
                    Order Details
                  </button>
                </h2>
                <button
                  brand={brand}
                  className="btn btn-primary ml-5 collapsed"
                  onClick={() => generatePDF(order)}
                >
                  Generate PDF
                </button>
              </div>

              <div
                id={`details${index}`}
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent={`#detailsContainer${index}`}
              >
                <div className="card-body">
                  {order.details.items.map((item, index) => (
                    <Link
                      to={`/product/${item.productId}`}
                      key={`item-${index}`}
                      className="item d-flex align-items-center "
                    >
                      <div className="image-container d-flex align-items-center justify-content-center">
                        <img
                          width="50"
                          src={
                            item.imageUrl !== ""
                              ? item.imageUrl
                              : brand?.icons.account.noProductImage
                          }
                          alt=""
                        />
                      </div>
                      <div className="item-info">
                        <div className="d-flex flex-row align-items-center justify-content-between"></div>
                        <p className="product-name">{item.productName}</p>
                        {item.ndc ? (
                          <p className="product-ndc">NDC: {item.ndc} </p>
                        ) : (
                          <></>
                        )}
                        <p>Price: ${item.price}</p>
                        <p>Quantity: {item.quantity}</p>
                      </div>
                      <div className="item-info-end text-left">
                        {order.details.shipComplete && (
                          <>
                            <p className="text-left">Get by</p>
                            <p className="text-left">
                              {formatDate(order?.getBy)}
                            </p>
                          </>
                        )}
                      </div>
                    </Link>
                  ))}

                  <div className="mt-2">
                    {order.details.items.length > 0 && (
                      <div className="d-flex align-items-center justify-content-between">
                        <b>Subtotal:</b> <div>${calcSubTotal(order)}</div>
                      </div>
                    )}

                    {order.details.shippingFee >= 0 && (
                      <div className="d-flex align-items-center justify-content-between">
                        <div>Shipping Fee:</div>
                        <div>${order.details.shippingFee.toFixed(2)}</div>
                      </div>
                    )}

                    {order.details.discount > 0 && (
                      <div className="d-flex align-items-center justify-content-between">
                        <div>Discount:</div>
                        <div>${order.details.discount.toFixed(2)}</div>
                      </div>
                    )}

                    <p className="shipping mt-3">
                      Shipping Address:
                      {formatShipping(order.details.shippingAddress)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {pedigreeList && pedigreeList.length > 0 &&
          status === "Pedigree" &&
          pedigreeList?.pedigrees.map((pedigree) => (
            <div
              key={pedigree.id}
              className="d-flex justify-content-between align-items-center"
            >
              {pedigree.name}
              <a href={pedigree.url} target="_blank" rel="noopener noreferrer">
                Open
              </a>
            </div>
          ))}

        {((order.details.items.length === 0 && status !== "Pedigree") ||
          (!pedigreeList && status === "Pedigree")) &&
          order.id !== selected && (
            <StyledLoadMoreButton
              brand={brand}
              className="load-btn d-block ml-auto mr-auto"
              onClick={() =>
                status !== "Pedigree"
                  ? handleLoadMore(order.id)
                  : handleGetPedigree(order.id, order.salesOrderNumber)
              }
            >
              {status !== "Pedigree" ? "Load more details" : "Get Pedigree"}
            </StyledLoadMoreButton>
          )}

        {getLoading && order.id === selected && (
          <div className="text-center">
            {status !== "Pedigree"
              ? "Loading details..."
              : "Loading pedigree..."}
          </div>
        )}
        <div className="mt-2">
          <div className="d-flex align-items-center justify-content-between mt-5">
            <div className="amount">Total:</div>
            <div>${order.details.total.toFixed(2)}</div>
          </div>
        </div>
      </div>
    );
  };

  const handlePageClick = (data) => {
    setLoading(true);
    // let s = status === 'All' ? null : status;
    let p = data.selected + 1;
    setPage(p);
    setOrders([]);
    // dispatch(getOrders(user?.username, s, p));
  };

  const handleStatusClick = (s) => {
    setLoading(true);
    // let newStatus = s === 'All' ? null : s;
    setStatus(s);
    setPage(1);
    setOrders([]);
    // dispatch(getOrders(user?.username, status, 1));
  };

  const handleLoadMore = (orderID) => {
    setSelected(orderID);
    setGetLoading(true);
    dispatch(getOrder(user?.username, orderID, brand.region));
  };

  const handleGetPedigree = (orderId, salesOrderNumber) => {
    setSelected(orderId);
    setGetLoading(true);
    dispatch(getPedigree(user?.username, salesOrderNumber, brand.region));
  };

  const handleSearch = (e) => {
    setLoading(true);
    setSearch(e.target.value.toUpperCase());
    setPage(1);
    setOrders([]);
  };

  const changeSearchBy = (v) => {
    if (v !== searchBy) {
      v === "order" && setLoading(true);
      setSearch("");
      setMonthsAgo(1);
      setOrders([]);
      setSearchBy(v);
    }
  };

  useEffect(() => {
    if (account?.ordersData?.orders) {
      // const sorted = account?.ordersData?.orders?.sort((a, b) =>
      //   a.salesOrderNumber < b.salesOrderNumber
      //     ? 1
      //     : b.salesOrderNumber < a.salesOrderNumber
      //       ? -1
      //       : 0
      // );
      const account_data = account?.ordersData?.orders;
      setOrders(account_data);
    }
    setLoading(false);
    setSelected("");
    setGetLoading(false);
    setDisable(false);
  }, [account]);

  useEffect(() => {
    let s = ["All", "Pedigree"].includes(status) ? null : status;
    if (search !== "" && brand) {
      clearTimeout(delayTimer);
      setDelayTimer(
        setTimeout(function () {
          setDisable(true);
          dispatch(
            getOrders(
              user?.username,
              s,
              page,
              search,
              searchBy,
              monthsAgo,
              brand.region
            )
          );
        }, 3000)
      );
    }
    if (search === "" && searchBy === "order" && brand) {
      setDisable(true);
      dispatch(
        getOrders(
          user?.username,
          s,
          page,
          search,
          searchBy,
          monthsAgo,
          brand.region
        )
      );
    }
  }, [status, page, search, searchBy, monthsAgo, brand]);

  useEffect(() => {
    // dispatch(getOrders(user?.username, null, 1));
    setStatus("All");
  }, []);

  return (
    <>
      <h1 className="title">Order History</h1>
      <div className="d-flex align-item-center justify-content-between order-top-container">
        <div className="search-container input-group">
          <div className="input-group-prepend">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled={disable}
            >
              Search by {searchBy}
            </button>
            <div className="dropdown-menu">
              <div
                className="dropdown-item"
                onClick={() => changeSearchBy("order")}
              >
                by order
              </div>
              <div
                className="dropdown-item"
                onClick={() => changeSearchBy("product")}
              >
                by product
              </div>
            </div>
          </div>
          <input
            className="form-control"
            type="text"
            name="search"
            value={search}
            placeholder={searchBy === "order" ? "Order #" : "NDC"}
            onChange={handleSearch}
            autoComplete="off"
            disabled={disable}
          />
        </div>
        {searchBy === "product" && (
          <div className="months-container input-group">
            <input
              className="form-control"
              type="number"
              name="monthsAgo"
              value={monthsAgo}
              min={1}
              onChange={(e) => {
                if (search !== "") {
                  setLoading(true);
                  setOrders([]);
                  setPage(1);
                }
                setMonthsAgo(e.target.value);
              }}
              autoComplete="off"
              disabled={disable}
            />
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon2">
                Month{monthsAgo > 1 && "s"} Ago
              </span>
            </div>
          </div>
        )}
      </div>
      <div>
        {searchBy === "order" && (
          <ul className="nav align-item-center justify-content-around order-nav">
            <li className={status === "All" ? "active" : ""}>
              <div onClick={() => handleStatusClick("All")}>All</div>
            </li>
            <li className={status === "Processing" ? "active" : ""}>
              <div onClick={() => handleStatusClick("Processing")}>
                Processing
              </div>
            </li>
            <li className={status === "Shipped" ? "active" : ""}>
              <div onClick={() => handleStatusClick("Shipped")}>Shipped</div>
            </li>
            <li className={status === "Pedigree" ? "active" : ""}>
              <div onClick={() => handleStatusClick("Pedigree")}>
                T3 Pedigree
              </div>
            </li>
          </ul>
        )}

        <div className="orders">
          {orders.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center orders-empty">
              {!account?.ordersData || loading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "No Order History"
              )}
            </div>
          ) : (
            displayedItems.map((order, index) => renderOrder(order, index))
          )}
          <div className="pagination-orders">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPageCount}
              onPageChange={handlePageClick}
              containerClassName={
                account?.ordersData?.count === 0 ||
                  loading ||
                  orders.length === 0
                  ? "pagination empty"
                  : "pagination"
              }
              activeClassName={"active"}
              // initialPage={0}
              forcePage={parseInt(page) - 1}
            />
          </div>
        </div>
      </div>
    </>
  );
};
