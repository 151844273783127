import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import styled from "styled-components";

import { HeaderNav } from '../components/partials/HeaderNav';
import { Footer } from '../components/partials/Footer';
import { ItemList } from '../components/cart/itemList';
import { OrderSummary } from '../components/cart/orderSummary';
import { NotificationBanner } from '../components/shared/warningNotification';

const StyledCartHeader = styled.div`
    ${props => props.brand && `
        background: ${props.brand.colors.cartBackground} !important;
    `}
`;

const StyledCartPage = styled.div`
    ${props => props.brand && `
        .cart-progress {
            background: ${props.brand.colors.cartBackground} !important;
        }
    `}
`;

export const CartContainer = () => {

    const account = useSelector((state) => state.account);
    const { brand } = account;

    return (
        <>
            <Helmet>
                <title>Cart | Premier Pharmaceuticals</title>
                <link rel="icon" href={brand?.icons.favIcon} />
            </Helmet>
            <HeaderNav />
            <StyledCartHeader
                brand={brand}
                className="cart-header container-fluid d-flex flex-column flex-md-row
                    align-items-start align-items-md-center justify-content-md-between justify-content-around">
                <span>Shopping Cart</span>
                <Link to="/shop" className='back-btn'>
                    {"<"}
                    &nbsp;&nbsp;Back to products
                </Link>
            </StyledCartHeader>

            <StyledCartPage brand={brand} className="cart-page container-fluid">
                {/* <h1 className="title">My Cart</h1> */}
                <NotificationBanner brand={brand} />
                <div className="main-content-container d-flex align-items-start">
                    <div className='cart-items-container d-flex flex-column align-items-start w-100 mb-4'>
                        <div className="cart-progress d-flex align-items-center justify-content-between">
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step1} alt="" />
                                <span>Cart</span>
                            </div>
                            <div>
                                <img height={14} src={brand?.icons.cart.arrow} alt="" />
                            </div>
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step2Next} alt="" />
                                <span>Shipping Information</span>
                            </div>
                            <div>
                                <img height={14} src={brand?.icons.cart.arrow} alt="" />
                            </div>
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step3Next} alt="" />
                                <span>Make Payment</span>
                            </div>
                            <div>
                                <img height={14} src={brand?.icons.cart.arrow} alt="" />
                            </div>
                            <div className="step d-flex align-items-center justify-content-center">
                                <img height={20} width={20} src={brand?.icons.cart.step4Next} alt="" />
                                <span>Complete</span>
                            </div>
                        </div>
                        <div className="cart-items">
                            <ItemList page={'cart'} brand={brand} />
                        </div>
                    </div>
                    <div className="right-container">
                        <OrderSummary page={'cart'} brand={brand} />
                    </div>
                </div>
            </StyledCartPage>
            <Footer />
        </>
    );
};
