import React, { useState, useEffect, useRef } from "react";
import Papa from 'papaparse';

import {
  addGroupPricingProduct,
  editGroupPricingProduct,
  deleteGroupPricingProduct,
  addGroupPricingUser,
  removeGroupPricingUser,
  deleteGroupPricing,
} from "../../actions/admin";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import fuzzysort from "fuzzysort";

const StyledAddButton = styled.button`
  ${props => props.brand && `
    background: ${props.brand.colors.theme} !important;
    border: 2px solid ${props.brand.colors.theme} !important;
  `}
`;
const StyledInput = styled.input`
  ${props => props.brand && `
    background: ${props.brand.colors.theme} !important;
    border: 2px solid ${props.brand.colors.theme} !important;
  `}
`;

const StyledPricingGroupTable = styled.div`
  ${props => props.brand && `
    .table .thead-dark th {
      // background-color: ${props.brand.colors.black} !important;
      // border-color: ${props.brand.colors.black} !important;
    }
  `}
`;

const initialState = {
  product: null,
  newPrice: 0,
};

export const Group = ({ group, index }) => {
  const admin = useSelector((state) => state.admin);
  const productsData = useSelector((state) => state.products);
  const account = useSelector((state) => state.account);
  const { brand } = account;
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [actionType, setActionType] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchProducts, setSearchProducts] = useState([]);
  const [file, setFile] = useState();
  const [disabled, setDisabled] = useState(true);
  const [addProductLoading, setAddProductLoading] = useState(false);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const uploadedProductCSV = async () => {
    setAddProductLoading(true);
    if (file) {
      try {
        const parsedData = await new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            complete: function (results) {
              resolve(results.data);
            },
            error: function (error) {
              reject(error);
            }
          });
        });

        for (const product of parsedData) {
          const ndc = product.ndc;
          const price = product.customPrice;
          const productData = filteredProducts.find(prod => prod.obj.ndc === ndc);

          if (productData) {
            const productToAdd = {
              ndc,
              lastPurchasePrice: productData?.obj?.lastPurchasePrice,
              productId: parseInt(productData.obj.id),
              productName: productData.obj.name,
              price: parseFloat(price)
            };
            await handleCsvProduct(group.id, productToAdd);
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    }
    setAddProductLoading(false);
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setDisabled(true);
  };

  const handleCsvProduct = async (groupId, productToAdd) => {
    await dispatch(addGroupPricingProduct(groupId, productToAdd, brand.region));
  }
  const handleAddProduct = (groupId) => {
    const productToAdd = {
      ...formData.product,
      productId: parseInt(formData.product.productId),
      price: parseFloat(formData.newPrice),
    };
    dispatch(addGroupPricingProduct(groupId, productToAdd, brand.region));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchUserChange = (e) => {
    setSearchUser(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const formatPrice = (price) => {
    var n = parseFloat(price).toFixed(2);
    return n;
  };

  const selectProduct = (product) => {
    const selectedProduct = {
      ndc: product.ndc,
      productId: product.id,
      productName: product.name,
      price: product.cost,
      lastPurchasePrice: product?.lastPurchasePrice
        ? product.lastPurchasePrice
        : "",
      markupPercentage: product?.markupPercentage
        ? product?.markupPercentage
        : 0,
    };
    setFormData({
      product: selectedProduct,
      newPrice: product.cost,
    });
    setSearch(product.name);
  };

  const selectUser = (user) => {
    const selectedUser = {
      company: user.company,
      email: user.email,
      username: user.username,
    };

    setSelectedUser(selectedUser);
    setSearchUser(user.company);
  };

  const handleAddUser = (groupId) => {
    dispatch(addGroupPricingUser(selectedUser, groupId, brand.region));
  };

  const getPrice = (lastPurchasePrice, markupPercentage) => {
    markupPercentage = markupPercentage ? markupPercentage : 0;
    return formatPrice(
      parseFloat(lastPurchasePrice) +
      (markupPercentage / 100) *
      parseFloat(lastPurchasePrice)
    )
  };

  useEffect(() => {
    let filterProducts = [];

    if (searchValue !== "") {
      filterProducts = group.pricingList.filter((product) =>
        product.productName.toLowerCase().includes(searchValue.toLowerCase()) || product.ndc.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearchProducts(filterProducts);
    }
    else {
      setSearchProducts(group.pricingList);
    }
  }, [searchValue, group.pricingList]);


  useEffect(() => {
    if (productsData.adminProducts) {
      let products, searchResult, changeTimer;

      changeTimer = setTimeout(() => {
        products = productsData.adminProducts.map((prod, key) => {
          return { ...prod, id: prod.id.toString() };
        });

        searchResult = fuzzysort.go(search, products, {
          keys: ["id", "name", "ndc"],
          all: true,
        });

        searchResult = searchResult.sort(function (a, b) {
          return a.obj.name > b.obj.name
            ? 1
            : a.obj.name === b.obj.name
              ? 0
              : -1;
        });

        setFilteredProducts(searchResult);
      }, 1000);

      return () => {
        clearTimeout(changeTimer);
      };
    }
  }, [search, productsData]);

  useEffect(() => {
    if (admin.users) {
      let users, searchResult, changeTimer;

      changeTimer = setTimeout(() => {
        users = admin.users.filter((u) => u.subAccount === false);

        searchResult = fuzzysort.go(searchUser, users, {
          keys: ["company", "email"],
          all: true,
        });

        searchResult = searchResult.sort(function (a, b) {
          return a.obj.company > b.obj.company
            ? 1
            : a.obj.company === b.obj.company
              ? 0
              : -1;
        });

        setFilteredUsers(searchResult);
      }, 1000);

      return () => {
        clearTimeout(changeTimer);
      };
    }
  }, [searchUser, admin]);

  useEffect(() => {
    setFormData(initialState);
    setSearch("");
    setActionType("");
    setSelectedProduct(null);
    setActionLoading(false);
    setSelectedUser(null);
    setSearchUser("");
    setSearchValue("");
    setSearchProducts(group.pricingList)
  }, [admin, group.pricingList]);

  useEffect(() => {
    if (file && filteredProducts.length)
      setDisabled(false);
  }, [file, filteredProducts])

  const handlePriceEdit = (e, product) => {
    selectedProduct?.lastPurchasePrice ?
    setSelectedProduct({
      ...selectedProduct,
      lastPurchasePrice: product?.price,
      price: e.target.value,
      markupPercentage:
        selectedProduct?.lastPurchasePrice &&
        formatPrice(
          ((e.target.value -
            selectedProduct?.lastPurchasePrice) *
            100) /
          selectedProduct?.lastPurchasePrice
        ),
    }) 
    : setSelectedProduct({
      ...selectedProduct,
      lastPurchasePrice: product?.price,
      price: e.target.value,
      markupPercentage: Number(e.target.value) > Number(product?.price) ?formatPrice(
        ((e.target.value -
          product?.price) *
          100) /
        product?.price
      ) : 0,
    })
  }

  const handleMarkupEdit = (e, product) => {
    setSelectedProduct({
      ...selectedProduct,
      lastPurchasePrice: product?.price,
      price:
        getPrice(
          product?.price,
          e.target.value
        ),
      markupPercentage: e.target.value,
    })
  }

  return (
    <div
      id={`group${index}`}
      className={"tab-pane fade " + (index === 0 ? "in active show" : "")}
    >
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h3 className="m-0">{group.name}</h3>
        <nav className="nav nav-pills">
          <a
            data-toggle="pill"
            className="nav-link active"
            href={`#groupUsers${index}`}
          >
            Users
          </a>
          <a
            data-toggle="pill"
            className="nav-link"
            href={`#groupProducts${index}`}
          >
            Products
          </a>
        </nav>
        <button
          className="btn btn-danger ml-2"
          onClick={() => dispatch(deleteGroupPricing(group.id, brand.region))}
        >
          Delete Group
        </button>
      </div>
      <div className="tab-content">
        <div className="tab-pane fade in active show" id={`groupUsers${index}`}>
          <StyledPricingGroupTable brand={brand} className="pricing-group-table">
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th className="no-wrap" scope="col">
                    User Name
                  </th>
                  <th className="no-wrap" scope="col">
                    Email
                  </th>
                  <th className="no-wrap" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {admin.users
                  .filter(
                    (u) =>
                      u.groupPricingId && u.groupPricingId.includes(group.id)
                  )
                  .map((user, userIndex) => (
                    <tr key={`groupUser${index}${userIndex}`}>
                      <td>{user.company}</td>
                      <td>{user.email}</td>
                      <td className="no-wrap">
                        <button
                          className="btn btn-danger ml-2"
                          onClick={() => {
                            dispatch(
                              removeGroupPricingUser(user, user.groupPricingId, brand.region)
                            );
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </StyledPricingGroupTable>
          <div className="d-flex align-items-end justify-content-between add-pricing-group">
            <div className="position-relative group-search-product w-75">
              <label>User</label>
              <input
                className="form-control"
                type="text"
                placeholder={admin.users ? "user" : "loading users..."}
                value={searchUser}
                onChange={handleSearchUserChange}
                disabled={!admin.users}
              />
              <div
                className="product-list-container list-group list-group-flush card position-absolute m-0 w-100 px-0 py-3"
                style={{
                  minHeight: "max-content",
                  maxHeight: "calc(100vh - 500px)",
                  overflow: "auto",
                }}
              >
                {filteredUsers.map((userData, userDataIndex) => (
                  <a
                    href="#"
                    className={
                      "list-group-item list-group-item-action px-3 " +
                      (selectedUser &&
                        selectedUser.username === userData.obj.username
                        ? "active"
                        : "")
                    }
                    key={`user${index}${userDataIndex}`}
                    onClick={() => selectUser(userData.obj)}
                  >
                    {userData.obj.company}
                  </a>
                ))}
              </div>
            </div>
            <StyledAddButton
              brand={brand}
              className="btn btn-primary w-50"
              onClick={() => handleAddUser(group.id)}
              disabled={!selectedUser}
            >
              Add User
            </StyledAddButton>
          </div>
        </div>
        <div className="tab-pane fade" id={`groupProducts${index}`}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="search-container input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Search
                </span>
              </div>
              <input
                className="form-control"
                type="text"
                name="search"
                value={searchValue}
                placeholder="Search by product name or ndc"
                onChange={handleSearch}
                autoComplete="off"
              />
            </div>
          </div>
          <StyledPricingGroupTable brand={brand} className="pricing-group-table">
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th className="no-wrap" scope="col">
                    Product Name
                  </th>
                  <th className="no-wrap" scope="col">
                    Product ID
                  </th>
                  <th scope="col">NDC</th>
                  <th scope="col">Price</th>
                  <th scope="col">Last Purchase Price</th>
                  <th scope="col">Floor Price</th>
                  <th scope="col">Mark Up</th>
                  <th className="no-wrap" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {searchProducts.map((product, productIndex) => (
                  <tr key={`product${index}${productIndex}`}>
                    <td>{product.productName}</td>
                    <td>{product.productId}</td>
                    <td className="no-wrap">{product.ndc}</td>
                    <td>
                      {actionType === "edit" &&
                        selectedProduct?.productId === product.productId ? (
                        <input
                          className="form-control"
                          type="number"
                          placeholder="price"
                          value={
                            (selectedProduct.price === product.price && selectedProduct.lastPurchasePrice)
                              ? getPrice(
                                selectedProduct?.lastPurchasePrice,
                                selectedProduct?.markupPercentage
                              )
                              : selectedProduct.price
                          }
                          onChange={(e) => handlePriceEdit(e, product)}
                        />
                      ) : product?.markupPercentage && product?.lastPurchasePrice ? (
                        getPrice(
                          product?.lastPurchasePrice,
                          product?.markupPercentage
                        )
                      ) : (
                        product.price
                      )}
                    </td>
                    <td>
                      {product?.lastPurchasePrice &&
                        `$${formatPrice(product?.lastPurchasePrice)}`}
                    </td>
                    <td>
                      {product?.lastPurchasePrice
                        ? `$${formatPrice(
                          parseFloat(product?.lastPurchasePrice) * 1.15
                        )}`
                        : null}
                    </td>
                    <td>
                      {actionType === "edit" &&
                        selectedProduct?.productId === product.productId ? (
                        <input
                          className="form-control"
                          type="number"
                          placeholder="mark up"
                          value={selectedProduct?.markupPercentage}
                          onChange={(e) => handleMarkupEdit(e, product)}
                        />
                      ) : product?.markupPercentage ? (
                        `${product?.markupPercentage}%`
                      ) : (
                        `${0}%`
                      )}
                    </td>
                    <td className="no-wrap">
                      {actionType === "edit" &&
                        selectedProduct?.productId === product.productId ? (
                        <>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              setActionType("");
                              setSelectedProduct(null);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-success ml-2"
                            style={{ minWidth: "50px" }}
                            onClick={() => {
                              setActionLoading(true);
                              dispatch(
                                editGroupPricingProduct(
                                  group.id,
                                  product.productId,
                                  {
                                    ...selectedProduct,
                                    price: parseFloat(selectedProduct.price),
                                    markupPercentage: parseFloat(
                                      selectedProduct.markupPercentage
                                    ),
                                    lastPurchasePrice: (selectedProduct.lastPurchasePrice)
                                  },
                                  brand.region
                                )
                              );
                            }}
                            disabled={
                              !selectedProduct ||
                              selectedProduct?.price < 1 ||
                              !selectedProduct?.markupPercentage?.length ||
                              actionLoading
                            }
                          >
                            {actionLoading ? "Saving..." : "Save"}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              setActionType("edit");
                              setSelectedProduct(product);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger ml-2"
                            onClick={() => {
                              setActionType("delete");
                              setSelectedProduct(product);
                              setActionLoading(true);
                              dispatch(
                                deleteGroupPricingProduct(
                                  group.id,
                                  product.productId,
                                  brand.region
                                )
                              );
                            }}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </StyledPricingGroupTable>
          <div className="d-flex align-items-end justify-content-between add-pricing-group">
            <div className="position-relative group-search-product w-50">
              <label>Product</label>
              <input
                className="form-control"
                type="text"
                placeholder={
                  productsData.adminProducts ? "product" : "loading products..."
                }
                value={search}
                onChange={handleSearchChange}
                disabled={!productsData.adminProducts}
              />
              <div
                className="product-list-container list-group list-group-flush card position-absolute m-0 w-100 px-0 py-3"
                tabindex="0"
                style={{
                  minHeight: "max-content",
                  maxHeight: "calc(100vh - 500px)",
                  overflow: "auto",
                }}
              >
                {filteredProducts.map((productData, productDataIndex) => (
                  <a
                    href="#"
                    className={
                      "list-group-item list-group-item-action px-3 " +
                      (formData.product?.productId === productData.obj.id
                        ? "active"
                        : "")
                    }
                    key={`productList${index}${productDataIndex}`}
                    onClick={() => selectProduct(productData.obj)}
                  >
                    {`${productData.obj.name},  (NDC: ${productData.obj.ndc})`}
                  </a>
                ))}
              </div>
            </div>
            <div className="w-25">
              <label>Price</label>
              <input
                className="form-control"
                type="number"
                placeholder="price"
                name="newPrice"
                value={formData.newPrice}
                onChange={handleChange}
                disabled={!productsData.adminProducts}
              />
            </div>

            <StyledAddButton
              brand={brand}
              className="btn btn-primary w-25"
              onClick={() => handleAddProduct(group.id)}
              disabled={!formData.product || formData.newPrice < 1}
            >
              Add Product
            </StyledAddButton>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-2">
            <StyledInput
              brand={brand}
              name="productsCsvFile"
              type="file"
              className="custom-csv-upload"
              style={{ width: "25%" }}
              accept=".csv"
              onChange={(e) => setFile(e.target.files[0])}
              ref={fileInputRef}
            />
            <StyledAddButton
              brand={brand}
              className="btn btn-primary w-25"
              onClick={() => filteredProducts && uploadedProductCSV()}
              disabled={disabled}
            >
              {addProductLoading ? (
                <div
                  className="spinner-border text-light spinner-border-sm"
                  role="sync"
                >
                  <span className="btn btn-primary w-25 sr-only">Uploading...</span>
                </div>
              ) : (
                "Upload CSV"
              )}
            </StyledAddButton>
          </div>
        </div>
      </div>
    </div>
  );
};
