import axios from "axios";
import { brands } from "../constants/brands";

// Dynamically change the API base URL to support multiple brands
let baseURL = process.env.REACT_APP_BACKEND_URL;

const currentURL = window.location.href;

for (let item in brands) {
  const regex = new RegExp(brands[item].name, 'gi');
  if (currentURL.match(regex)) {
    baseURL = process.env[brands[item].apiUrlKey];
    break;
  }
}

const API = axios.create({ baseURL: baseURL });
// const user = JSON.parse(localStorage.getItem('profile'));

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    const token = JSON.parse(localStorage.getItem("profile")).accessToken;
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

export const logIn = (formData, region) => API.post(`/login?region=${region}`, formData);

export const signUp = (formData, region) => API.post(`/signup?region=${region}`, formData);

export const changePassword = (username, formData, region) =>
  API.put(`/user/${username}/change-password?region=${region}`, formData);

export const confirmForgotPassword = (username, formData, region) =>
  API.post(`/user/${username}/confirm-password?region=${region}`, formData);

export const forgotPassword = (username, region) =>
  API.post(`/user/${username}/forgot-password?region=${region}`);

export const getCountries = () => API.get(`/countries`);

export const getStates = (countryCode) =>
  API.get(`/states?countryCode=${countryCode}`);

export const getMethodsOfCollection = () => API.get(`/methods-of-collection`);

export const verifyAccount = (formData) =>
  API.post(`/user/${formData.email}/verify?code=${formData.code}`);

export const resendCode = (email) =>
  API.post(`/user/${email}/resend-verification`);

export const logOut = (username, region) => API.post(`/user/${username}/logout?region=${region}`);

export const getAccount = (username, region) => API.get(`/user/${username}?region=${region}`);

export const getNetsuiteAccount = (username) =>
  API.get(`/customers/${username}`);

export const getOrders = (
  username,
  status,
  page,
  search,
  searchBy,
  monthsAgo,
  region
) => {
  if (searchBy === "order") {
    const url = `/${username}/orders?`
    let query = [];

    page && query.push(`page=${page}`);
    status && query.push(`status=${status}`);
    search && query.push(`soNumber=${search}`);
    region && query.push(`region=${region}`);
    query = query.join("&");

    return API.get(`${url}${query}`);
  } else {
    const url = `/${username}/orders/history?`;
    let query = [];

    page && query.push(`page=${page}`);
    monthsAgo && query.push(`monthsAgo=${monthsAgo}`);
    search && query.push(`ndc=${search}`);
    region && query.push(`region=${region}`);

    return API.get(`${url}${query}`);
  }
};

export const addUserLicense = (formData,username) =>
  API.post(`/upload-license/${username}`, formData);

export const getOrder = (username, orderID, region) =>
  API.get(`/${username}/orders/${orderID}?region=${region}`);

export const getPedigree = (username, salesOrderNumber, region) =>
  API.get(`/${username}/sales-order/${salesOrderNumber}/pedigree?region=${region}`);

export const putAccount = (username, accountData) =>
  API.put(`/user/${username}`, accountData);

export const putLicense = (username, licenseData) =>
  API.put(`/user/${username}/license`, licenseData);

export const updateEmail = (username, formData) =>
  API.put(`/user/${username}/change-email`, formData);

export const addAddresses = (username, formData) =>
  API.post(`/user/${username}/addresses`, formData);

export const getAllAddresses = (username) =>
  API.get(`/user/${username}/addresses`);

export const getAddressesById = (username, id) =>
  API.get(`/user/${username}/addresses/${id}`);

export const deleteAddressesById = (username, id) =>
  API.delete(`/user/${username}/addresses/${id}`);

export const updateAddressesById = (username, id, formData) =>
  API.put(`/user/${username}/addresses/${id}`, formData);

export const makeDefaultAddress = (username, id) =>
  API.put(`/user/${username}/addresses/${id}/default`);

export const makeDefaultAddressBilling = (username, id) => {
  API.put(`/user/${username}/addresses/${id}/default-billing`);
};

export const getProducts = (
  name,
  category,
  sortBy,
  sortOrder,
  page,
  stockSort
) =>
  API.get(
    "/products?" +
    (name ? `name=${name}` : "") +
    (category ? `category=${category}` : "") +
    (sortBy ? `&sortBy=${sortBy}&order=${sortOrder}` : "") +
    (page ? `&page=${page}` : "") +
    (stockSort ? "&stockSort=true" : "&stockSort=false")
  );

export const getFavoriteProducts = (
  username,
  name,
  sortBy,
  sortOrder,
  page,
  stockSort
) =>
  API.get(
    `/products/${username}/favorites` +
    (sortBy || page || name ? "?" : "") +
    (name ? `name=${name}` : "") +
    (name && sortBy ? "&" : "") +
    (sortBy ? `sortBy=${sortBy}&order=${sortOrder}` : "") +
    (sortBy && page ? "&" : "") +
    (page ? `page=${page}` : "") +
    (stockSort ? "&stockSort=true" : "&stockSort=false")
  );

export const getProduct = (id) => API.get(`/products/${id}`);

export const getRequestPrice = (username) =>
  API.get(`/user/${username}/request-pricing`);

export const requestStock = (username, formData) =>
  API.post(`/user/${username}/request-stock`, formData);

export const getShortDated = (region) => API.get(`/products/short-dated?region=${region}`);

export const getPreferred = (username) => API.get(`/favorites/${username}`);

export const addPreferred = (username, formData) =>
  API.post(`/favorites/${username}/add`, formData);

export const removePreferred = (username, formData) =>
  API.post(`/favorites/${username}/remove`, formData);

export const getSearch = (name) =>
  API.get("/products?" + (name ? `name=${name}` : ""));

export const getCart = (username) => API.get(`/cart/${username}`);

export const getCount = (username) => API.get(`/cart/${username}/count`);

export const addCart = (username, product) =>
  API.post(`/cart/${username}/add`, product);

export const updateCart = (username, product) =>
  API.put(`/cart/${username}/update`, product);

export const removeCart = (username, productId) =>
  API.post(`/cart/${username}/remove`, { productId });

export const discount = (discountCode) =>
  API.get(`/coupon?code=${discountCode}`);

export const addDiscount = (formData) =>
  API.post(`/add-coupon`, formData);

export const getDiscounts = () => API.get(`/get-coupons`);

export const deleteCoupon = (id) => API.delete(`/delete-coupon/${id}`,  );

export const getCards = (username) => API.get(`/square/customers/${username}`);

export const addCard = (username, formData) =>
  API.post(`/${username}/cards`, formData);

export const removeCard = (username, id) =>
  API.delete(`/${username}/cards/${id}`);

export const getDefaultCard = (username) =>
  API.get(`/cards/${username}/default-payment-method`);

export const setDefaultCard = (username, body) =>
  API.post(`/cards/${username}/default-payment-method`, body);

export const payment = (username, formData) =>
  API.post(`/${username}/payment`, formData);

export const paymentByTerms = (username, formData, region) =>
  API.post(`/${username}/pay-by-terms?region=${region}`, formData);

export const paymentViaPaypal = (username, formData, region) =>
  API.post(`/${username}/pay-via-paypal?region=${region}`, formData);

// UPLOAD AVATAR
export const getAvatar = (username) => API.get(`/user/${username}/photo`);

export const postAvatar = (username, image) =>
  API.post(`/user/${username}/photo`, image);

// ADMIN
export const getUsers = (region) => API.get(`/admin/users?region=${region}`);

export const getCustomProducts = (username, region) =>
  API.get(`/custom-price/${username}?region=${region}`);

export const createCustomProduct = (formData) =>
  API.post(`/custom-price/create`, formData);

export const updateCustomProduct = (id, formData) =>
  API.put(`/custom-price/${id}`, formData);

export const removeCustomProduct = (id) => API.delete(`/custom-price/${id}`);

export const loginAdminUser = (formData, region) =>
  API.post(`/admin/login-as-user?region=${region}`, formData);

export const loginAdmin = (formData, region) => API.post(`/admin/login?region=${region}`, formData);

export const confirmUser = (formData, region) =>
  API.post(`/admin/confirm-user?region=${region}`, formData);

export const enableUser = (username, region) => {
  API.post(`/admin/${username}/enable?region=${region}`);
};

export const disableUser = (username, region) => {
  API.post(`/admin/${username}/disable?region=${region}`);
};

export const setInventoryAPI = (username,isEnabled, region) => 
  API.post(`/admin/${username}/inventory?region=${region}&globalInventory=${isEnabled}`);

export const importUser = (formData) =>
  API.post(`/admin/import-customer`, formData);

export const exportCSV = () => API.get(`/custom-price/export`);

export const createSubAccount = (formData, region) =>
  API.post(`/admin/sub-signup?region=${region}`, formData);

export const updateUserNetsuiteID = (username, formData, region) =>
  API.put(`/admin/${username}/netsuite-id?region=${region}`, formData);

export const syncCustomPricing = (username, region) =>
  API.post(`/admin/${username}/custom-price-sync?region=${region}`);

export const sendEmailToUser = (username, region) =>
  API.post(`/admin/${username}/send-email-to-user?region=${region}`);

export const addNewSubscriber = async (email, region) => 
  await API.post(`/add-subscriber/${email}?region=${region}`);

export const syncProducts = (region) => API.post(`/sync/products?region=${region}`);

export const getCustomProjectsNetsuite = (username, region) =>
  API.get(`/custom-price/${username}/netsuite?region=${region}`);

export const upsertCustomProjectsNetsuite = (username, formData, region) =>
  API.post(`/custom-price/${username}/upsert?region=${region}`, formData);

export const removeCustomProjectsNetsuite = (username, formData, region) =>
  API.delete(`/custom-price/${username}/remove?region=${region}`, { data: formData });


export const getOrderLogs = (region) => API.get(`/admin/order-logs?region=${region}`);

export const getAllSubscribers = (region) => API.get(`/get-subscribers?region=${region}`);

export const getProductQueue = (region) => API.get(`/product-queue?region=${region}`);

export const upsertProductToQueue = (product, region) =>
  API.post(`/product-queue?region=${region}`, product);

export const getAutomationDate = (region) => API.get(`/admin/automation-date?region=${region}`);

export const setAutomationDate = (automationDate, region) =>
  API.post(`/admin/automation-date?region=${region}`, automationDate);

export const getGroupPricing = (region) => API.get(`/group-pricing?region=${region}`);

export const getGroupPricingById = (groupPricingId, region) =>
  API.get(`/group-pricing/${groupPricingId}?region=${region}`);

export const createGroupPricing = (formData, region) =>
  API.post(`/group-pricing?region=${region}`, formData);

export const deleteGroupPricing = (groupPricingId, region) =>
  API.delete(`/group-pricing/${groupPricingId}?region=${region}`);

export const addGroupPricingProduct = (groupPricingId, formData, region) =>
  API.post(`/group-pricing/${groupPricingId}?region=${region}`, formData);

export const editGroupPricingProduct = (groupPricingId, productId, formData, region) =>
  API.put(`/group-pricing/${groupPricingId}/update/${productId}?region=${region}`, formData);

export const deleteGroupPricingProduct = (groupPricingId, productId, region) =>
  API.delete(`/group-pricing/${groupPricingId}/remove/${productId}?region=${region}`);

export const addGroupPricingUser = (username, formData, region) =>
  API.post(`/user/${username}/add-to-group?region=${region}`, formData);

export const removeGroupPricingUser = (username, formData, region) =>
  API.post(`/user/${username}/remove-from-group?region=${region}`, formData);

// PRODUCTS v2
export const getCategories = (region) => API.get(`/products/categories?region=${region}`);

export const getProductsv2 = (region, globalInventory) =>
  API.get(`/products?region=${region}&globalInventory=${globalInventory}`);

export const getAdminProducts = (region) => API.get(`/products?mode=admin&region=${region}`);

export const getProductv2 = (productId) => API.get(`/products/${productId}`);

export const getFavProductsv2 = (username, region) =>
  API.get(`/products/${username}/favorites?region=${region}`);

export const getAllSalesPrices = (region) =>
  API.get(`/sale-price?region=${region}`);

export const upsertSalesPrice = (formData, region) =>
  API.post(`/sale-price?region=${region}`, formData);

export const deleteSalePrice = (formData, region) =>
  API.delete(`/sale-price/${JSON.stringify(formData)}?region=${region}`);
