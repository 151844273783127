import React from 'react';
import { HeaderNav } from '../../components/partials/HeaderNav';
import { Footer } from '../../components/partials/Footer';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import styled from 'styled-components';
import MAErrorImage from "../../assets/img/brands/mid-america/404.svg";

const StyledMidAmericaErrorPage = styled.div`
    ${props => props.brand && `
        background: ${props.brand.colors.linearBackground};
        height: 100vh;

        .error-subheading-text {
            margin-top: 3rem;
            color: ${props.brand.colors.notFoundText};
            font-size: 20px;
            font-weight: 400;
            padding: 0 4rem;
            text-align: center;
        }

        img {
            width: 50%;
            margin-top: 10rem;
        }

        @media (min-width: 786px) { 
            img {
                width: auto;
            }
        }

        a {
            background: ${props.brand.colors.theme};
            padding: 12px 32px 12px 32px;
            border-radius: 40px;
            color: ${props.brand.colors.white};
            margin-top: 2rem;
        }
    `}
`;

export const ErrorPageContainer = () => {
    const account = useSelector((state) => state.account);
    const { brand } = account;

    return (
        <>
            <Helmet>
                <title>404 | Premier Pharmaceuticals</title>
                <link rel="icon" href={brand?.icons.favIcon} />
            </Helmet>
            <HeaderNav />
            {brand && brand.name === "premierpharma" &&
                <div
                    brand={brand}
                    className="d-flex flex-column align-items-center justify-content-center errorPage">
                    <h1 className="error-heading-text">404</h1>
                    <p className="error-subheading-text">Something went wrong</p>
                    <a href={`${brand && process.env[brand.homePageUrlKey]}`} className="primaryButton">
                        Return to Home page
                    </a>
                </div>}

            {brand && brand.name === "premiermid-america" &&
                <StyledMidAmericaErrorPage
                    brand={brand}
                    className="d-flex flex-column align-items-center justify-content-center errorPage">
                    <img src={MAErrorImage} alt="Not found" />
                    <p className="error-subheading-text">We are sorry, but the page you`ve been looking for is not found!</p>
                    <a href={`${brand && process.env[brand.homePageUrlKey]}`}>
                        Return to Home page
                    </a>
                </StyledMidAmericaErrorPage>}
            {brand && brand.name === "premierpharma" && <Footer />}
        </>
    )
};